import { useService } from 'react-service-injector';
import { HealthCheck, HealthCheckFile, HealthService } from '../../services/HealthService';
import { useEffect, useState } from 'react';
import { HealthCheckTable } from './HealthCheckTable';

export const HealthDetails = () => {
  const healthService = useService(HealthService);
  const [files, setFiles] = useState<HealthCheckFile[]>();
  const [fileIndex, setFileIndex] = useState(0);
  const [currentHealth, setCurrentHealth] = useState<HealthCheck>();
  const [showComments, setShowComments] = useState(false);

  useEffect(() => {
    healthService.listHealthChecks().then(setFiles);
  }, [healthService]);

  useEffect(() => {
    if (files) {
      setCurrentHealth(undefined);
      healthService.getHealthCheck(files[fileIndex].id).then(setCurrentHealth);
    }
  }, [fileIndex, files, healthService]);

  return (
    <div className="health-details">
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">{(files || [])[fileIndex]?.date || 'Loading...'}</label>
            <div className="buttons">
              <button
                className="button is-square"
                disabled={fileIndex === (files?.length || 0) - 1}
                onClick={() => setFileIndex((prevState) => prevState + 1)}
              >
                <i className="fas fa-arrow-left" />
              </button>
              <button
                className="button is-square"
                disabled={fileIndex === 0}
                onClick={() => setFileIndex((prevState) => prevState - 1)}
              >
                <i className="fas fa-arrow-right" />
              </button>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="field is-pulled-right">
            <label className="label">Show comments</label>
            <input
              id="includeLeave"
              className="switch"
              type="checkbox"
              checked={showComments}
              onChange={(e) => setShowComments(e.target.checked)}
            />
            <label htmlFor="includeLeave" />
          </div>
        </div>
      </div>
      {currentHealth ? (
        <>
          <h2>Total team health: {currentHealth.healthPercentage}%</h2>
          <HealthCheckTable healthCheck={currentHealth} showComments={showComments} />
        </>
      ) : (
        <i className="fas fa-spin fa-refresh" />
      )}
    </div>
  );
};
