import { PlantCard } from '../components/office/PlantCard';
import { useEffect, useState } from 'react';
import { Plant, OfficeService } from '../services/OfficeService';
import { useService } from 'react-service-injector';

export const PlantsPage = () => {
  const [plants, setPlants] = useState<Plant[]>([]);
  const plantsService = useService(OfficeService);

  useEffect(() => {
    plantsService.getPlants().then(setPlants);
  }, [plantsService]);

  return (
    <div>
      <div className="columns">
        <div className="column">
          <h1>Plants</h1>
        </div>
      </div>
      <div className="columns">
        {plants.map((plant, idx) => (
          <PlantCard key={idx} plant={plant} />
        ))}
      </div>
    </div>
  );
};
