import React, { ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import { useService } from 'react-service-injector';
import { TimeChimpService, User } from '../services/TimeChimpService';

const Context = React.createContext<{ authUser: AuthUser; users: User[] } | undefined>(undefined);

export interface AuthUser {
  email: string;
  firstName: string;
  lastName: string;
  picture: string;
  timeChimpId?: number;
  contractWorkWeekHours: number;
}

export function UserDataProvider({ children }: { children: ReactNode }): ReactElement | null {
  const [authUser, setAuthUser] = useState<AuthUser>();
  const [users, setUsers] = useState<User[]>();
  const timeChimp = useService(TimeChimpService);

  useEffect(() => {
    if (!authUser) {
      fetch('/auth/me')
        .then((r) => r.json())
        .then((result) => {
          if (result.error) {
            window.location.href =
              '/auth/login?redirect=' +
              encodeURIComponent(`${window.location.pathname}${window.location.search}${window.location.hash}`);
          } else {
            timeChimp.getUsers().then((newUsers) => {
              setUsers(
                newUsers
                  .filter((user) => user.tagNames.includes('developer') || user.tagNames.includes('pm'))
                  .sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
              );
              setAuthUser(result);
            });
          }
        });
    }
  }, [authUser, users, timeChimp]);

  if (!authUser || !users) {
    // TODO: fancy loading screen?
    return null;
  }

  return <Context.Provider value={{ authUser, users }}>{children}</Context.Provider>;
}

export function useUserData(): AuthUser {
  const data = useContext(Context);
  if (!data) {
    throw new Error('Called useUserData from outside a UserDataProvider');
  }
  return data.authUser;
}

export function useUsers(): User[] {
  const data = useContext(Context);
  if (!data) {
    throw new Error('Called useUsers from outside a UserDataProvider');
  }
  return data.users;
}

export function useUser(timeChimpId: number): User {
  // TODO catch if user is not in the list anymore
  const users = useUsers();
  const user = users.find((user) => user.id === timeChimpId);
  if (!user) {
    throw new Error(`Called useUser, but no matching user was found for id ${timeChimpId}`);
  }
  return user;
}

export function useCurrentUser(): User {
  const { timeChimpId } = useUserData();
  if (!timeChimpId) {
    throw new Error('Called useCurrentUser, but no TimeChimp was set');
  }
  return useUser(timeChimpId);
}
