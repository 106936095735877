import { ApiService } from './ApiService';
import { Injector } from 'react-service-injector';

export interface HealthCheckFile {
  id: string;
  name: string;
  date: string;
}

export interface HealthCheck {
  date: string;
  dimensions: string[];
  entries: Entry[];
  healthPercentage: number;
  scoreCounts: Record<NonNullable<Score>, number>;
}

export type Score = null | 0 | 1 | 8;

export interface Entry {
  participant: string;
  dimensions: Record<string, DimensionValue>;
}

export interface DimensionValue {
  score: Score;
  comments: string;
}

export class HealthService {
  private readonly api: ApiService;

  constructor(injector: Injector) {
    this.api = injector.resolve(ApiService);
  }

  public listHealthChecks(): Promise<HealthCheckFile[]> {
    return this.api.jsonGet('/health/checks');
  }

  public getHealthCheck(id: string): Promise<HealthCheck> {
    return this.api.jsonGet(`/health/checks/${id}`);
  }

  public getAllHealthChecks(): Promise<HealthCheck[]> {
    return this.api.jsonGet('/health/all');
  }
}
