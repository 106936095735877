import { useState } from 'react';

export const AbsenceInput = () => {
  const [date, setDate] = useState('');
  const [hours, setHours] = useState(0);
  return (
    <div>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">Date</label>
            <div className="control">
              <input
                type="date"
                value={date}
                className="input is-large"
                onChange={(e) => setDate(e.currentTarget.value)}
              />
            </div>
          </div>
        </div>
        <div className="column">
          <label className="label">Uren</label>
          <div className="control">
            <input
              className="input is-large"
              value={hours > 0 ? hours : ''}
              onChange={(e) => setHours(+e.currentTarget.value)}
              type="number"
              placeholder="8"
            ></input>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">Type</label>
            <div className="select">
              <select className="select">
                <option>Verlof</option>
                <option>Ziek</option>
                <option>Feestdag</option>
                <option>Bijzonder verlof</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">Omschrijving</label>
            <div className="control">
              <input className="input" type="text" placeholder="Omschrijving"></input>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
