import { useEffect, useState } from 'react';
import { useService } from 'react-service-injector';
import { Results, TimeChimpService, TimeDto } from '../services/TimeChimpService';
import { DateTime } from 'luxon';
import { FinancialResults } from '../components/FinancialResults';
import { Series, SeriesBarChart } from '../charts/SeriesBarChart';
import { useUsers } from '../hooks/useUserData';

type Period = 'month' | 'week';

function nowWithOffset(period: Period, offset: number) {
  const now = DateTime.now();
  switch (period) {
    case 'month':
      return now.plus({
        month: offset,
      });
    case 'week':
      return now.plus({
        week: offset,
      });
  }
}

function describePeriod(period: Period, offset: number) {
  const then = nowWithOffset(period, offset);
  switch (period) {
    case 'month':
      return then.toFormat('MMMM');
    case 'week':
      return `Week ${then.weekNumber}`;
  }
}

export const FinancialPage = () => {
  const timesService = useService(TimeChimpService);
  const users = useUsers();
  const [period, setPeriod] = useState<Period>('week');
  const [offset, setOffset] = useState(0);
  const [results, setResults] = useState<Results>();
  const [resultsToDate, setResultsToDate] = useState<Results>();
  const [times, setTimes] = useState<Series[]>();

  useEffect(() => {
    (async () => {
      setResults(undefined);
      setResultsToDate(undefined);
      setTimes(undefined);
      const offsetDate = nowWithOffset(period, offset);

      let times: TimeDto[];
      let results: Results;
      let resultsToDate: Results;

      switch (period) {
        case 'month':
          [times, results, resultsToDate] = await Promise.all([
            timesService.getTimesForMonth(offsetDate.weekYear, offsetDate.month),
            timesService.getResultsByMonth(offsetDate.weekYear, offsetDate.month),
            timesService.getResultsByMonth(offsetDate.weekYear, offsetDate.month, true),
          ]);
          break;
        case 'week':
          [times, results, resultsToDate] = await Promise.all([
            timesService.getTimesForWeek(offsetDate.weekYear, offsetDate.weekNumber),
            timesService.getResultsByWeek(offsetDate.weekYear, offsetDate.weekNumber),
            timesService.getResultsByWeek(offsetDate.weekYear, offsetDate.weekNumber, true),
          ]);
          break;
        default:
          return;
      }

      const knownDates: string[] = [];
      const hoursByPersonAndDay: Record<string, Record<string, number>> = {};

      // restructure flat list into map[ person ][ map[date][hours] ]
      times.forEach((entry) => {
        if (users.findIndex((user) => user.id === entry.userId) === -1) {
          // This is not a "real" user but an importer account
          return;
        }
        if (!(entry.userDisplayName in hoursByPersonAndDay)) {
          hoursByPersonAndDay[entry.userDisplayName] = {};
        }
        const person = hoursByPersonAndDay[entry.userDisplayName];
        const dateString = DateTime.fromISO(entry.date).toFormat('yyyy-MM-dd');
        person[dateString] = (person[dateString] || 0) + entry.hours;
        if (!knownDates.includes(dateString)) {
          knownDates.push(dateString);
        }
      });
      knownDates.sort();

      setResults(results);
      setResultsToDate(resultsToDate);
      setTimes(
        Object.entries(hoursByPersonAndDay).map(([person, days]) => ({
          name: person,
          value: knownDates.map((date) => ({
            y: days[date] || 0,
            name: DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('EEEE MMMM dd'),
          })),
        }))
      );
    })();
  }, [period, offset, timesService, users]);

  return (
    <>
      <div className="columns">
        <div className="column">
          <h1>Financial Report</h1>
        </div>
      </div>
      <div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">{describePeriod(period, offset)}</label>
              <div className="buttons">
                <button className="button is-square" onClick={() => setOffset(offset - 1)}>
                  <i className="fas fa-arrow-left" />
                </button>
                <button className="button  is-square" disabled={offset === 0} onClick={() => setOffset(offset + 1)}>
                  <i className="fas fa-arrow-right" />
                </button>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label">Period</label>
              <div className="select">
                <select
                  className="select"
                  onChange={(e) => {
                    setPeriod(e.target.value as Period);
                    setOffset(0);
                  }}
                >
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <h2>Infi Amsterdam only</h2>
        <FinancialResults results={results?.internal} />
        {offset === 0 ? (
          <>
            <h2>To date (Amsterdam only)</h2>
            <FinancialResults results={resultsToDate?.internal} />
          </>
        ) : (
          <>
            <h2>
              Including 3<sup>rd</sup> party
            </h2>
            <FinancialResults results={results?.total} />
          </>
        )}

        <SeriesBarChart title={`Hours over ${period}`} series={times || []} />
      </div>
    </>
  );
};
