export function formatDateLong(date: string): string {
  return Intl.DateTimeFormat('en-GB', {
    dateStyle: 'full',
  }).format(new Date(date));
}

export function formatDateDayMonth(date: string): string {
  return Intl.DateTimeFormat('nl-NL', {
    day: 'numeric',
    month: 'long',
  }).format(new Date(date));
}
