import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { sharedBarChartOptions } from './options';

export interface Series {
  name: string;
  value: { y: number; name: string }[];
}

export interface Props {
  title: string;
  series: Series[];
}

export const SeriesBarChart = ({ series, title }: Props) => {
  const options: Highcharts.Options = {
    ...sharedBarChartOptions,
    series: series.map((s) => ({
      name: s.name,
      type: 'column',
      data: s.value,
      showInLegend: false,
    })),
    title: { text: `<h1>${title}</h1>` },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
