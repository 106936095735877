import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import { NavLink } from 'react-router-dom';
import { useService } from 'react-service-injector';
import { useUsers } from '../hooks/useUserData';
import { DayPlanning, PlanningService } from '../services/PlanningService';
import { classNames } from '../utils/classNames';
import { UserPlanning } from '../components/planning/UserPlanning';
import { ActivityPlanning } from '../components/planning/ActivityPlanning';

interface Props {
  view: 'users' | 'activities';
}

export const PlanningPage = ({ view }: Props) => {
  const users = useUsers();
  const [planning, setPlanning] = useState<DayPlanning[]>([]);
  const planningService = useService(PlanningService);
  const [date, setDate] = useState(DateTime.now());

  useEffect(() => {
    const start = date.startOf('week').toISODate();
    const end = date.endOf('week').toISODate();
    if (start && end) {
      planningService.getLocationPlanning('amsterdam', start, end).then(setPlanning);
    }
  }, [planningService, date]);

  const start = date.startOf('week');
  return (
    <>
      <div className="columns">
        <div className="column">
          <h1>Planning</h1>
        </div>
      </div>
      <div className="planning-page">
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Week {date.weekNumber}</label>
              <div className="buttons">
                <button className="button is-square" onClick={() => setDate(date.minus({ week: 1 }))}>
                  <i className="fas fa-arrow-left" />
                </button>
                <button className="button  is-square" onClick={() => setDate(date.plus({ week: 1 }))}>
                  <i className="fas fa-arrow-right" />
                </button>
              </div>
            </div>
          </div>
          <div className="column column-last">
            <div className="field">
              <label className="label">Sorteer</label>
              <div className="buttons has-addons">
                <NavLink
                  className={classNames('button', view === 'users' && 'is-link is-selected')}
                  to="/team/planning"
                >
                  Gebruikers
                </NavLink>
                <NavLink
                  className={classNames('button', view === 'activities' && 'is-link is-selected')}
                  to="/office/planning"
                >
                  Activiteiten
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="table-container">
          <table className={`table ${view === 'users' && 'is-striped'}`}>
            <thead>
              <tr>
                <th></th>
                <th>
                  <h2>Monday</h2>
                  <div>{start.toFormat('yyyy-MM-dd')}</div>
                </th>
                <th>
                  <h2>Tuesday</h2>
                  <div>{start.plus({ days: 1 }).toFormat('yyyy-MM-dd')}</div>
                </th>
                <th>
                  <h2>Wednesday</h2>
                  <div>{start.plus({ days: 2 }).toFormat('yyyy-MM-dd')}</div>
                </th>
                <th>
                  <h2>Thursday</h2>
                  <div>{start.plus({ days: 3 }).toFormat('yyyy-MM-dd')}</div>
                </th>
                <th>
                  <h2>Friday</h2>
                  <div>{start.plus({ days: 4 }).toFormat('yyyy-MM-dd')}</div>
                </th>
              </tr>
            </thead>
            {view === 'activities' ? (
              <ActivityPlanning planning={planning} users={users} />
            ) : (
              <UserPlanning planning={planning} users={users} />
            )}
            <tfoot></tfoot>
          </table>
        </div>
      </div>
    </>
  );
};
