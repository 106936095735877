import { Climate } from '../../services/OfficeService';

interface ClimateCardProps {
  climate: Climate;
}

interface EntityProps {
  name?: string;
  value?: number | string;
  unit?: string;
}

const Entity = ({ name, value, unit }: EntityProps) => {
  return (
    <div className="column">
      <p className="heading">{name}</p>
      <p className="title">
        {value}
        {unit}
      </p>
    </div>
  );
};

export const ClimateCard = ({ climate }: ClimateCardProps) => {
  return (
    <>
      <h3>{climate.friendlyName}</h3>
      <div className="columns">
        <Entity name="temperature" value={climate.currentTemperature} unit="°C" />
        <Entity name="humidity" value={climate.currentHumidity} unit="%" />
        <Entity name="heater status" value={climate.status} unit="" />
        <Entity name="desired temperature" value={climate.targetTemperature} unit="°C" />
        {climate.status === 'heating' ? (
          <>
            <Entity
              name="reached"
              value={Math.round((climate.currentTemperature / climate.targetTemperature) * 100)}
              unit="%"
            />
          </>
        ) : (
          <>
            <Entity />
          </>
        )}
      </div>
    </>
  );
};
