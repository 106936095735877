import { ApiService } from './ApiService';

function getToken() {
  const url = new URL(window.location.toString());
  return url.searchParams.get('token');
}

export class TokenApiService extends ApiService {
  public constructor() {
    super({
      Authorization: `Bearer ${getToken()}`,
    });
  }
}
