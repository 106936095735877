import { ApiService } from './ApiService';
import { TokenApiService } from './TokenApiService';

export interface CalendarEvent {
  humanDate: string;
  date: string;
  time: string;
  description: string;
  who: string;
}

export class CalendarService {
  private readonly api: ApiService = new TokenApiService();

  public getFutureEvents(): Promise<CalendarEvent[]> {
    return this.api.jsonGet('/calendar/future');
  }
}
