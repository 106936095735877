import { ApiService } from './ApiService';
import { Injector } from 'react-service-injector';

export interface Photo {
  title: string;
  publicUrl: string;
}

export interface Gallery {
  title: string;
  photos: Photo[];
}

export class GalleryService {
  private readonly api: ApiService;

  constructor(injector: Injector) {
    this.api = injector.resolve(ApiService);
  }

  public getGalleries(): Promise<Gallery[]> {
    return this.api.jsonGet('/galleries');
  }
}
