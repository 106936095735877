export interface MenuItem {
  name: string;
  icon: string;
  link: string;
}

export interface MenuCategory extends MenuItem {
  children: MenuItem[];
  color?: MenuColor;
}

export type MenuColor = 'blue' | 'green' | 'yellow' | 'red' | 'grey';

export const menu: MenuCategory[] = [
  {
    name: 'Home',
    icon: 'home',
    link: '/',
    children: [],
  },
  {
    name: 'Personal',
    icon: 'user',
    link: '/personal',
    color: 'blue',
    children: [
      {
        name: 'Dashboard',
        icon: 'chart-pie',
        link: '/personal/dashboard',
      },
      {
        name: 'Expenses',
        icon: 'coins',
        link: '/personal/expenses',
      },
      {
        name: 'Mileage',
        icon: 'car',
        link: '/personal/mileage',
      },
      {
        name: 'Absences',
        icon: 'umbrella-beach',
        link: '/personal/absences',
      },
    ],
  },
  {
    name: 'Team',
    icon: 'users',
    link: '/team',
    color: 'green',
    children: [
      {
        name: 'KPIs',
        icon: 'bullseye',
        link: '/team/kpis',
      },
      {
        name: 'Planning',
        icon: 'calendar',
        link: '/team/planning',
      },
      {
        name: 'Financial',
        icon: 'chart-line',
        link: '/team/financial',
      },
      {
        name: 'Health',
        icon: 'notes-medical',
        link: '/team/health',
      },
      {
        name: 'Alpaca Import',
        icon: 'file-import',
        link: '/team/project-import',
      },
    ],
  },
  {
    name: 'Office',
    icon: 'building',
    link: '/office',
    color: 'yellow',
    children: [
      {
        name: 'Planning',
        icon: 'calendar',
        link: '/office/planning',
      },
      {
        name: 'Plants',
        icon: 'leaf',
        link: '/office/plants',
      },
      {
        name: 'Climate',
        icon: 'temperature-half',
        link: '/office/climate',
      },
      {
        name: 'Gallery',
        icon: 'images',
        link: '/office/gallery',
      },
    ],
  },
];
