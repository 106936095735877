import { ApiService } from './ApiService';
import { TokenApiService } from './TokenApiService';

export interface Birthday {
  date: string;
  name: string;
}

export class BirthdayService {
  private readonly api: ApiService = new TokenApiService();

  public getFutureBirthdays(): Promise<Birthday[]> {
    return this.api.jsonGet('/birthday/future');
  }
}
