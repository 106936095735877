import { Injector } from 'react-service-injector';
import { ApiService } from './ApiService';
import { htmlDecode } from '../utils/htmlDecode';
import { DateTime } from 'luxon';

export interface CreateTime {
  projectId: number;
  projectTaskId: number;
  userId: number;
  notes: string;
  date: string;
  hours: number;
  start: string;
  end: string;
  startEnd: string;
}

export interface TimeDto {
  id: number;
  customerId: number;
  customerName: string;
  projectId: number;
  projectName: string;
  projectTaskId: number;
  taskId: number;
  taskName: string;
  userId: number;
  userDisplayName: string;
  date: string;
  hours: number;
  notes: string;
  startEnd: string;
  start: string;
  end: string;
  modified: Date;
}

export interface ProjectTask {
  id: number;
  projectId: number;
  taskId: number;
  taskName: string;
  unspecified: boolean;
}

export interface Project {
  id: number;
  name: string;
  customerId: number;
  customerName: string;
  projectTasks: ProjectTask[];
}

export interface User {
  id: number;
  displayName: string;
  userName: string;
  tagNames: string[];
}

export interface Result {
  revenue: number;
  costs: number;
  marginPercentage: number;
  hourlyRate: number;
}

export interface Results {
  startDate: string;
  endDate: string;
  external: Result;
  internal: Result;
  total: Result;
}

export interface Customer {
  address: string;
}

const excludeLeave = (item: TimeDto) => item.taskName !== 'Verlof' && item.taskName !== 'Bijzonder Verlof';
const excludeSick = (item: TimeDto) => item.taskName !== 'Ziek';

const decodeHtmlItem = (item: TimeDto) => ({ ...item, notes: htmlDecode(item.notes) });

export class TimeChimpService {
  private readonly api: ApiService;

  constructor(injector: Injector) {
    this.api = injector.resolve(ApiService);
  }

  public getTimesForWeek(weekYear: number, weekNumber: number): Promise<TimeDto[]> {
    return this.api
      .jsonGet<TimeDto[]>(`/hours/week/${weekYear}/${weekNumber}`)
      .then((data) => data.map(decodeHtmlItem));
  }

  public getTimesForMonth(year: number, month: number): Promise<TimeDto[]> {
    return this.api.jsonGet<TimeDto[]>(`/hours/month/${year}/${month}`).then((data) => data.map(decodeHtmlItem));
  }

  public getTimesForProject(id: number, start: string, end: string): Promise<TimeDto[]> {
    return this.api.jsonGet<TimeDto[]>(`/hours/${id}/${start}/${end}`).then((data) => data.map(decodeHtmlItem));
  }

  public async getTimes(
    start: string,
    end: string,
    options?: { includeLeave?: boolean; includeSick?: boolean }
  ): Promise<TimeDto[]> {
    if (!options) {
      return this.api.jsonGet<TimeDto[]>(`/hours/range/${start}/${end}`).then((data) => data.map(decodeHtmlItem));
    }
    return (await this.getTimes(start, end))
      .filter((item) => options.includeLeave || excludeLeave(item))
      .filter((item) => options.includeSick || excludeSick(item));
  }

  public getResultsForDate(startDate: DateTime, endDate: DateTime): Promise<Results> {
    return this.api.jsonGet(`/finance/date/${startDate.toFormat('yyyy-MM-dd')}/${endDate.toFormat('yyyy-MM-dd')}`);
  }

  public getResultsByWeek(weekYear: number, weekNumber: number, partial?: boolean): Promise<Results> {
    return this.api.jsonGet(`/finance/week/${weekYear}/${weekNumber}${partial ? '?partial=true' : ''}`);
  }

  public getResultsByMonth(year: number, month: number, partial?: boolean): Promise<Results> {
    return this.api.jsonGet(`/finance/month/${year}/${month}${partial ? '?partial=true' : ''}`);
  }

  public getProjects(): Promise<Project[]> {
    return this.api.jsonGet(`/hours/projects`);
  }

  public getCustomers(): Promise<Customer[]> {
    return this.api.jsonGet('hours/customers');
  }

  public getUsers(): Promise<User[]> {
    return this.api.jsonGet('/hours/users');
  }

  public getExternalUsers(): Promise<User[]> {
    return this.api.jsonGet('/hours/users?tag=Extern');
  }

  public async postTimes(times: CreateTime[]): Promise<TimeDto[]> {
    const responses: TimeDto[] = [];
    // We intentionally wait for each otherwise we get 500's from TimeChimp
    for (const time of times) {
      responses.push(await this.api.jsonPost<CreateTime, TimeDto>('/hours/time', time));
    }
    return responses;
  }

  public isNonBillable(time: TimeDto): boolean {
    return (time.customerName === 'Infi Amsterdam B.V.' && time.projectName === 'Intern') || time.taskName === 'Intern';
  }
}
