import { useEffect, useState } from 'react';
import { OfficeService, Climate, Weather } from '../services/OfficeService';
import { useService } from 'react-service-injector';
import { ClimateCard } from '../components/office/ClimateCard';
import { WeatherCard } from '../components/office/WeatherCard';

export const ClimatePage = () => {
  const [climate, setClimate] = useState<Climate[]>([]);
  const [weather, setWeather] = useState<Weather[]>([]);
  const officeService = useService(OfficeService);

  useEffect(() => {
    officeService.getClimate().then(setClimate);
  }, [officeService]);
  useEffect(() => {
    officeService.getWeather().then(setWeather);
  }, [officeService]);

  return (
    <div>
      <div className="columns">
        <div className="column">
          <h1>Climate</h1>
        </div>
      </div>
      <h2>Weather</h2>
      {weather.map((entity, idx) => (
        <WeatherCard key={idx} weather={entity} />
      ))}
      <h2>Indoor</h2>
      {climate.map((entity, idx) => (
        <ClimateCard key={idx} climate={entity} />
      ))}
    </div>
  );
};
