import { DateRangeFormat, DateRangeSelector, Range } from '../components/DateRangeSelector';
import { ChangeEvent, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { AlpacaService, Item, Project, Task } from '../services/AlpacaService';
import { Project as TCProject, ProjectTask, TimeChimpService, TimeDto } from '../services/TimeChimpService';
import { useService } from 'react-service-injector';
import { ProjectImportService } from '../services/ProjectImportService';
import { TaskList } from '../components/TaskList';
import { Modal } from '../components/Modal';

const today = DateTime.now();
const startOfBillingPeriod = today.day > 15 ? today.set({ day: 1 }) : today.set({ day: 16 }).minus({ month: 1 });
const endOfBillingPeriod = today.day > 15 ? today.set({ day: 15 }) : today.set({ day: 1 }).minus({ day: 1 });

export const ProjectImportPage = () => {
  const alpacaService = useService(AlpacaService);
  const timesService = useService(TimeChimpService);
  const importService = useService(ProjectImportService);

  const [defaultUserId, setDefaultUserId] = useState<number>();
  const [alpacaProjects, setAlpacaProjects] = useState<Project[]>();
  const [timeChimpProjects, setTimeChimpProjects] = useState<TCProject[]>();

  const [range, setRange] = useState<Range>({
    start: startOfBillingPeriod.toFormat(DateRangeFormat),
    end: endOfBillingPeriod.toFormat(DateRangeFormat),
  });

  const [sourceProject, setSourceProject] = useState<Project>();
  const [sourceTasks, setSourceTasks] = useState<Task[]>();
  const [sourceTimes, setSourceTimes] = useState<Item[]>();

  const [targetProject, setTargetProject] = useState<TCProject>();
  const [targetTasks, setTargetTasks] = useState<ProjectTask[]>();
  const [targetTimes, setTargetTimes] = useState<TimeDto[]>();

  const [taskMappings, setTaskMappings] = useState<Record<number, number>>({});

  const [createdItems, setCreatedItems] = useState<TimeDto[]>();
  const [importing, setImporting] = useState(false);

  const [showWarnings, setShowWarnings] = useState(false);
  const [showDuplicates, setShowDuplicates] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setImporting(false);
    }, 2000);
  });

  // Load the default user
  useEffect(() => {
    setDefaultUserId(undefined);
    timesService.getExternalUsers().then((users) => setDefaultUserId(users[0].id));
  }, [timesService]);

  // Load Infi Utrecht Projects
  useEffect(() => {
    setAlpacaProjects(undefined);
    alpacaService.getProjects().then((projects) => {
      // Only our projects
      setAlpacaProjects(projects.filter((project) => project.name.endsWith(' - Infi Nijmegen')));
    });
  }, [alpacaService]);

  // Load Infi Nijmegen Projects
  useEffect(() => {
    setTimeChimpProjects(undefined);
    timesService.getProjects().then((projects) => {
      // We are only interested in internal project. Nothing from Infi Utrecht B.V. or Infi Nijmegen B.V.
      setTimeChimpProjects(projects.filter((project) => !project.customerName.match(/Infi .* B\.V\./)));
    });
  }, [timesService]);

  // Try to auto-detect the target project
  useEffect(() => {
    if (!sourceProject || !timeChimpProjects) {
      // no project -> no guess
      return;
    }

    setTargetProject(undefined);
    const guessedTarget = importService.findRelatedProject(sourceProject, timeChimpProjects);
    setTargetProject(guessedTarget);
  }, [sourceProject, importService, timeChimpProjects]);

  // load source tasks
  useEffect(() => {
    if (!sourceProject) {
      return;
    }
    setSourceTasks(undefined);
    alpacaService.getProjectTasks(sourceProject.id).then((tasks) =>
      setSourceTasks([
        ...tasks,
        {
          projectId: sourceProject.id,
          id: -1,
          name: 'non-billable',
        },
      ])
    );
  }, [sourceProject, alpacaService]);

  // load source hours
  useEffect(() => {
    if (!sourceProject || !range.start || !range.end) {
      return;
    }
    setSourceTimes(undefined);
    alpacaService
      .getItems(sourceProject.id, range.start + ' 00:00:00', range.end + ' 23:59:59')
      .then((data) => setSourceTimes(data.filter((item) => item.billable)));
  }, [sourceProject, alpacaService, range]);

  // load target tasks

  useEffect(() => {
    if (!targetProject) {
      return;
    }
    setTargetTasks(targetProject.projectTasks.filter((task) => !task.unspecified));
  }, [targetProject, timesService]);

  // load target hours
  useEffect(() => {
    if (!targetProject || !range.start || !range.end) {
      return;
    }
    setTargetTimes(undefined);
    timesService
      .getTimesForProject(targetProject.id, range.start, range.end)
      .then((items) => setTargetTimes(items.filter((i) => i.userDisplayName === 'Infi Utrecht')));
  }, [targetProject, timesService, range]);

  // Try to auto-detect task mappings
  useEffect(() => {
    if (!sourceTasks || !targetTasks) {
      return;
    }
    setTaskMappings(importService.createTaskMappings(sourceTasks, targetTasks));
  }, [sourceTasks, targetTasks, importService]);

  const mappingDone = sourceTasks && targetTasks && Object.entries(taskMappings).length === sourceTasks.length;

  const transformedItems =
    sourceTimes && targetTasks && defaultUserId && taskMappings && targetTimes
      ? importService
          .transform(
            sourceTimes,
            Object.keys(taskMappings).reduce(
              (accu, m) => ({ ...accu, [Number(m)]: targetTasks.find((t) => taskMappings[Number(m)] === t.id) }),
              {}
            ),
            { defaultUserId, users: [] }
          )
          .map((item) => {
            const duplicate = targetTimes.find((tc) => {
              return (
                item.target.date === tc.date &&
                item.target.userId === tc.userId &&
                item.target.hours === tc.hours &&
                item.target.start.startsWith(tc.start) &&
                tc.notes.includes(item.target.notes)
              );
            });

            return {
              ...item,
              duplicate,
            };
          })
      : [];

  function handleMappingChange(sourceTask: number) {
    return (e: ChangeEvent<HTMLSelectElement>) => {
      if (!e.target.value) {
        setTaskMappings((mappings) => {
          const copy = { ...mappings };
          delete copy[sourceTask];
          return copy;
        });
      } else {
        const targetTask = parseInt(e.target.value);
        setTaskMappings((mappings) => ({
          ...mappings,
          [sourceTask]: targetTask,
        }));
      }
    };
  }

  const overLappingItems = transformedItems.filter((item) => !!item.duplicate);
  const newItems = transformedItems.filter((item) => !item.duplicate);

  async function handleImport() {
    setImporting(true);
    setCreatedItems(await timesService.postTimes(newItems.map((item) => item.target)));
    setImporting(false);
  }

  return (
    <>
      <div className="columns">
        <div className="column">
          <h1>Project Import</h1>
        </div>
      </div>
      <div className="columns">
        <DateRangeSelector range={range} onRangeChange={setRange} />
      </div>
      <h2>Project</h2>
      {alpacaProjects && timeChimpProjects ? (
        <div className="card">
          <div className="card-content">
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">Infi Utrecht</label>
                  <div className="select">
                    <select
                      className="select"
                      value={sourceProject?.id || ''}
                      onChange={(e) => {
                        setSourceProject(alpacaProjects.find((p) => p.id.toString() === e.target.value));
                      }}
                    >
                      <option>Select a project...</option>
                      {alpacaProjects?.map((project) => (
                        <option key={project.id} value={project.id}>
                          {project.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">Infi Nijmegen</label>
                  <div className="select">
                    <select
                      className="select"
                      value={targetProject?.id || ''}
                      onChange={(e) => {
                        setTargetProject(timeChimpProjects.find((p) => p.id.toString() === e.target.value));
                      }}
                    >
                      <option>Select a project...</option>
                      {timeChimpProjects?.map((project) => (
                        <option key={project.id} value={project.id}>
                          {project.name} - {project.customerName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <i className="fas fa-spin fa-refresh" />
      )}
      {sourceProject && targetProject && (
        <>
          <h2>Tasks</h2>
          <div className="card">
            <div className="card-content">
              <div className="columns">
                <div className="column">
                  {sourceTasks && targetTasks ? (
                    <>
                      {sourceTasks.map((task) => (
                        <div key={task.id} className="field">
                          <label className="label">Infi Utrecht: {task.name}</label>
                          <div className="select">
                            <select
                              className="select"
                              value={taskMappings[task.id] || ''}
                              onChange={handleMappingChange(task.id)}
                            >
                              <option>Select mapping...</option>
                              {targetTasks.map((task) => (
                                <option key={task.id} value={task.id}>
                                  {task.taskName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <i className="fas fa-spin fa-refresh" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {sourceProject && targetProject && mappingDone && sourceTimes && targetTimes && (
        <>
          <h2>Time tracked</h2>
          <div className="columns">
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <h3>Infi Utrecht</h3>
                  <h3>{sourceProject.name}</h3>
                  <div className="columns">
                    <div className="column has-text-centered">
                      <span className="heading">Count</span>
                      <span className="title">{sourceTimes.length}</span>
                    </div>
                    <div className="column has-text-centered">
                      <span className="heading">Total</span>
                      <span className="title">{sourceTimes.reduce((total, next) => total + next.duration, 0)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <h3>Infi Nijmegen</h3>
                  <h3>{targetProject.name}</h3>
                  <div className="columns">
                    <div className="column has-text-centered">
                      <span className="heading">Count</span>
                      <span className="title">{targetTimes.length}</span>
                    </div>
                    <div className="column has-text-centered">
                      <span className="heading">Total</span>
                      <span className="title">{targetTimes.reduce((total, next) => total + next.hours, 0)}</span>
                    </div>
                    <div className="column has-text-centered">
                      <span className="heading">New Hours</span>
                      <span className="title">{newItems.reduce((total, next) => total + next.target.hours, 0)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {overLappingItems.length > 0 && (
        <>
          <h2 className="has-text-danger">
            Warnings!{' '}
            <button className="is-small is-link button" onClick={() => setShowWarnings((prev) => !prev)}>
              Show
            </button>
          </h2>

          {showWarnings && overLappingItems.length > 0 && (
            <div className="card">
              <div className="card-content">
                <h3>{overLappingItems.length} Overlapping Items</h3>
                <TaskList
                  times={overLappingItems
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    .map((item) => item.duplicate!)
                    .map((duplicate) => ({
                      id: duplicate.id,
                      description: duplicate.notes,
                      warning: true,
                      date: duplicate.date,
                      time: duplicate.hours,
                    }))}
                />
              </div>
            </div>
          )}
        </>
      )}
      {newItems && (
        <>
          <h2>
            {newItems.length} New Entries
            <button className="is-small is-link button" onClick={() => setShowDuplicates((prev) => !prev)}>
              Show Duplicates
            </button>
          </h2>
          <div className="columns">
            <div className="column is-half">
              <div className="card">
                <div className="card-content">
                  <h3>Infi Utrecht</h3>
                  <TaskList
                    times={(showDuplicates ? transformedItems : newItems).map(({ source }) => ({
                      id: source.taskId,
                      date: source.datetime,
                      description: `[${source.userName}] ${source.description}`,
                      time: source.duration,
                      warning: !source.billable,
                    }))}
                  />
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="card">
                <div className="card-content">
                  <h3>Infi Nijmegen</h3>
                  <TaskList
                    times={(showDuplicates ? transformedItems : newItems).map(({ target, duplicate }) =>
                      duplicate
                        ? undefined
                        : {
                            date: target.date,
                            description: target.notes + ' - ' + target.projectTaskId,
                            time: target.hours,
                          }
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="buttons is-centered">
            <button className="button is-danger is-large" onClick={handleImport}>
              Fire Away!
            </button>
          </div>
        </>
      )}
      {importing && (
        <Modal>
          <div className="has-text-centered">
            <h3 className="title">Importing...</h3>
            <i className="title has-text-white fas fa-spin fa-refresh" />
          </div>
        </Modal>
      )}
      {createdItems && (
        <Modal onClose={() => setCreatedItems(undefined)}>
          <div className="has-text-centered">
            <h1>Import complete!</h1>
            <div className="card">
              <div className="card-header">
                <p className="card-header-title">Imported Items</p>
              </div>
              <div className="card-content">
                <TaskList
                  times={createdItems.map((item) => ({
                    id: item.id,
                    date: item.date,
                    description: item.notes,
                    time: item.hours,
                  }))}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
