import { ApiService } from './ApiService';
import { Injector } from 'react-service-injector';
import { TimeDto } from './TimeChimpService';

export interface AbsenceDto {
  startDate: string;
  endDate: string;
  taskId: number;
  taskName: string;
  userId: number;
  userDisplayName: string;
  times: TimeDto[];
}

export class AbsenceService {
  public static readonly NAME = 'AbsenceService';

  private readonly api: ApiService;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiService);
  }

  public getAbsences(start: string, end: string, userId?: number): Promise<AbsenceDto[]> {
    return this.api.jsonGet(`/absences/range/${start}/${end}`, { userId });
  }
}
