import { useService } from 'react-service-injector';
import { HealthCheck, HealthService } from '../../services/HealthService';
import { useEffect, useState } from 'react';
import { HealthChart } from '../../charts/HealthChart';

export const HealthOverview = () => {
  const healthService = useService(HealthService);
  const [checks, setChecks] = useState<HealthCheck[]>();

  useEffect(() => {
    healthService.getAllHealthChecks().then(setChecks);
  }, [healthService]);

  return (
    <div className="health-overview">
      {checks ? <HealthChart healthChecks={checks} /> : <i className="fas fa-spin fa-refresh" />}
    </div>
  );
};
