import { Mileage, MileageStatus } from '../services/MileageService';
import { Customer } from '../services/CustomerImportService';
import { formatDateLong } from '../utils/date';
import { useUser } from '../hooks/useUserData';

export interface Props {
  mileage: Mileage;
  customers: Customer[];
  needsCheck: boolean;
}

export const MileageCard = ({ mileage, customers, needsCheck }: Props) => {
  const user = useUser(mileage.userId);
  let BoxStyling: string;
  if (needsCheck) {
    BoxStyling = 'box warning-card';
  } else {
    BoxStyling = 'box';
  }

  function updateDisplayedAddress(isDestination: boolean) {
    //TODO: change home address to "Home".
    let address;
    if (isDestination) {
      address = mileage.toAddress;
    } else {
      address = mileage.fromAddress;
    }
    const addressSimplifier = /^([a-zA-Z\s]+)\s*(\d+)/;
    const addressSplit = address.match(addressSimplifier);
    if (!addressSplit) {
      return address.substring(0, address.indexOf(','));
    }
    let displayedAddress = addressSplit[1] + addressSplit[2];
    const addressToCompare = displayedAddress.toLowerCase().replace(/\s+/g, '');
    for (const customer of customers) {
      if (customer.address) {
        const customerAddressSplit = customer.address.match(addressSimplifier);
        if (customerAddressSplit) {
          const customerAddress = (customerAddressSplit[1] + customerAddressSplit[2]).toLowerCase().replace(/\s+/g, '');
          if (customerAddress === addressToCompare) displayedAddress = customer.name;
        }
      }
    }
    return displayedAddress;
  }
  return (
    <div className={BoxStyling}>
      <div className="columns">
        <div className="column">
          <div className="has-text-weight-bold">{formatDateLong(mileage.date)}</div>
          <div>{user.displayName}</div>
        </div>
        <div className="column">
          <div>
            {mileage.customerName} ({mileage.projectName})
          </div>
          <div>{mileage.distance} km</div>
        </div>
        <div className="column">
          <div>From: {updateDisplayedAddress(false)}</div>
          <div>To: {updateDisplayedAddress(true)}</div>
        </div>
        <div className="column tags are-medium">
          {mileage.billable && <span className="tag">Billable</span>}
          {needsCheck && <span className="tag is-danger is-light">Check Billability!</span>}
          {mileage.status === MileageStatus.invoiced ? (
            mileage.customerName.includes(`Infi Nijmegen`) ? (
              <span className="tag is-warning is-light">Submitted</span>
            ) : (
              <span className="tag is-warning is-light">Invoiced</span>
            )
          ) : null}
          {<span>{mileage.type}</span>}
        </div>
      </div>
    </div>
  );
};
