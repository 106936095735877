import React from 'react';
import { HealthCheck } from '../../services/HealthService';

export interface Props {
  healthCheck: HealthCheck;
  showComments: boolean;
}

export const HealthCheckTable: React.FC<Props> = ({ healthCheck, showComments }) => {
  return (
    <table className="health-check-table table is-bordered">
      <thead>
        <tr>
          <th />
          {healthCheck.dimensions.map((d) => (
            <th key={d}>{d}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {healthCheck.entries.map((e) => (
          <tr key={e.participant}>
            <th>{e.participant}</th>
            {healthCheck.dimensions.map((d) => (
              <td key={d} className={`score-${e.dimensions[d].score}`}>
                {showComments && e.dimensions[d].comments}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
