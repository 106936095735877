import { ApiService } from './ApiService';
import { Injector } from 'react-service-injector';

export interface Plant {
  friendlyName: string;
  officialName: string;
  happiness: string;
  status: {
    [key: string]: {
      min: number;
      max: number;
      value: number;
      ok: boolean;
    };
  };
  info: {
    display_pid: string;
    image: string;
  };
}

export interface Climate {
  friendlyName: string;
  status: string;
  targetTemperature: number;
  currentTemperature: number;
  currentHumidity: number;
}

export interface Weather {
  friendlyName: string;
  status: string;
  temperature: number;
  humidity: number;
  forecastMsg: string;
}

export class OfficeService {
  public static readonly NAME = 'OfficeService';

  private readonly api: ApiService;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiService);
  }

  public getPlants(): Promise<Plant[]> {
    return this.api.jsonGet('/office/plants');
  }

  public getClimate(): Promise<Climate[]> {
    return this.api.jsonGet('/office/climate');
  }

  public getWeather(): Promise<Weather[]> {
    return this.api.jsonGet('/office/weather');
  }
}
