import { ApiService } from './ApiService';
import { Injector } from 'react-service-injector';

export interface Customer {
  id: number;
  name: string;
  address: string;
  city: string;
}

export class CustomersService {
  public static readonly NAME = 'CustomerImportService';

  private readonly api: ApiService;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiService);
  }

  public getCustomers(): Promise<Customer[]> {
    return this.api.jsonGet('/hours/customers');
  }
}
