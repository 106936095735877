import { DateTime } from 'luxon';
import { useState } from 'react';
import { KpiColumn } from '../components/KpiColumn';

function nowWithOffset(offset: number) {
  const now = DateTime.now();

  return now.plus({
    days: offset,
  });
}

function describePeriod(offset: number) {
  return nowWithOffset(offset).toFormat('yyyy-MM-dd (ccc)');
}

export const KpiPage = () => {
  const [offset, setOffset] = useState(0);

  const current = nowWithOffset(offset).minus({ days: 1 });
  const currentWeek = current.startOf('week');
  const previousWeek = currentWeek.minus({ days: 7 });

  return (
    <div>
      <div className="columns">
        <div className="column">
          <h1>KPIs</h1>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">{describePeriod(offset)}</label>
            <div className="buttons">
              <button className="button is-square" onClick={() => setOffset(offset - 1)}>
                <i className="fas fa-arrow-left" />
              </button>
              <button className="button  is-square" disabled={offset === 0} onClick={() => setOffset(offset + 1)}>
                <i className="fas fa-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column kpi-column">
          <div>
            <h2>Previous Seven Days</h2>
            <div>
              <small>
                {current.minus({ days: 6 }).toFormat('yyyy-MM-dd')}-{current.toFormat('yyyy-MM-dd')}
              </small>
            </div>
          </div>
          <KpiColumn
            startDate={current.minus({ days: 6 })}
            endDate={current}
            healthDate={current.endOf('week').minus({ days: 2 })}
          />
        </div>
        <div className="column kpi-column">
          <div>
            <h2>Week {currentWeek.toFormat('yyyy-WW')}</h2>
            <div>
              <small>
                {currentWeek.startOf('week').toFormat('yyyy-MM-dd')}-{currentWeek.endOf('week').toFormat('yyyy-MM-dd')}
              </small>
            </div>
          </div>
          <KpiColumn
            startDate={currentWeek}
            endDate={currentWeek.plus({ days: 6 })}
            healthDate={currentWeek.plus({ days: 4 })}
          />
        </div>
        <div className="column kpi-column">
          <div>
            <h2>Week {previousWeek.toFormat('yyyy-WW')}</h2>
            <div>
              <small>
                {previousWeek.startOf('week').toFormat('yyyy-MM-dd')}-
                {previousWeek.endOf('week').toFormat('yyyy-MM-dd')}
              </small>
            </div>
          </div>
          <KpiColumn
            startDate={previousWeek}
            endDate={previousWeek.plus({ days: 6 })}
            healthDate={previousWeek.plus({ days: 4 })}
          />
        </div>
      </div>
    </div>
  );
};
