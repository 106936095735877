import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

export const DateRangeFormat = 'yyyy-MM-dd';

export interface Range {
  start?: string;
  end?: string;
}

export interface Props {
  range: Range;
  onRangeChange: (range: Range) => void;
}

const predefinedRange: Record<string, { start: () => string; end: () => string }> = {
  custom: { start: () => '', end: () => '' },
  thisWeek: {
    start: () => DateTime.now().startOf('week').toFormat(DateRangeFormat),
    end: () => DateTime.now().endOf('week').toFormat(DateRangeFormat),
  },
  lastWeek: {
    start: () => DateTime.now().startOf('week').minus({ weeks: 1 }).toFormat(DateRangeFormat),
    end: () => DateTime.now().endOf('week').minus({ weeks: 1 }).toFormat(DateRangeFormat),
  },
  lastTwoWeeks: {
    start: () => DateTime.now().startOf('week').minus({ weeks: 2 }).toFormat(DateRangeFormat),
    end: () => DateTime.now().endOf('week').minus({ weeks: 1 }).toFormat(DateRangeFormat),
  },
  thisMonth: {
    start: () => DateTime.now().startOf('month').toFormat(DateRangeFormat),
    end: () => DateTime.now().endOf('month').toFormat(DateRangeFormat),
  },
  lastMonth: {
    start: () => DateTime.now().startOf('month').minus({ months: 1 }).toFormat(DateRangeFormat),
    end: () => DateTime.now().endOf('month').minus({ months: 1 }).toFormat(DateRangeFormat),
  },
  thisYear: {
    start: () => DateTime.now().startOf('year').toFormat(DateRangeFormat),
    end: () => DateTime.now().endOf('year').toFormat(DateRangeFormat),
  },
  lastYear: {
    start: () => DateTime.now().startOf('year').minus({ year: 1 }).toFormat(DateRangeFormat),
    end: () => DateTime.now().endOf('year').minus({ year: 1 }).toFormat(DateRangeFormat),
  },
};

export const DateRangeSelector: React.FC<Props> = ({ range: { start, end }, onRangeChange }) => {
  const [selectedRange, setSelectedRange] = useState<string>();
  const [startDate, setStartDate] = useState(start || predefinedRange.thisWeek.start());
  const [endDate, setEndDate] = useState(end || predefinedRange.thisWeek.end());

  useEffect(() => {
    setSelectedRange(findPredefinedRange(startDate, endDate));
    onRangeChange({ start: startDate, end: endDate });
  }, [startDate, endDate, onRangeChange]);

  const handlePredefinedRangeChange = (predef: string) => {
    const range = predefinedRange[predef] || predefinedRange['custom'];
    const start = range.start();
    const end = range.end();
    setStartDate(range.start());
    setEndDate(range.end());
    onRangeChange({ start, end });
  };

  const findPredefinedRange = (start?: string, end?: string): string => {
    if (!start || !end) {
      return 'custom';
    }

    const range = Object.entries(predefinedRange).find(([_, range]) => start === range.start() && end === range.end());
    return range ? range[0] : 'custom';
  };
  return (
    <>
      <div className="column">
        <div className="field">
          <label className="label">Date range</label>
          <div className="control">
            <div className="select">
              <select
                className="select"
                value={selectedRange}
                onChange={(e) => handlePredefinedRangeChange(e.target.value)}
              >
                <option value="thisWeek">This week</option>
                <option value="lastWeek">Last week</option>
                <option value="lastTwoWeeks">Last two weeks</option>
                <option value="thisMonth">This month</option>
                <option value="lastMonth">Last month</option>
                <option value="thisYear">This year</option>
                <option value="lastYear">Last year</option>
                <option value="custom">Custom</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="field">
          <label className="label">Start date</label>
          <div className="control">
            <input
              type="date"
              value={startDate}
              className="input"
              onChange={(e) => setStartDate(e.currentTarget.value)}
            />
          </div>
        </div>
      </div>
      <div className="column">
        <div className="field">
          <label className="label">End date</label>
          <input type="date" value={endDate} className="input" onChange={(e) => setEndDate(e.currentTarget.value)} />
        </div>
      </div>
    </>
  );
};
