import { ApiService } from './ApiService';
import { Injector } from 'react-service-injector';

export interface Expense {
  id: number;
  userId: number;
  customerName: string;
  projectName: string;
  categoryName: string;
  date: string;
  billable: boolean;
  rate: number;
  tax: number;
  status: number;
}

export enum expenseStatus {
  invoiced = 3,
}

export class ExpensesService {
  private readonly api: ApiService;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiService);
  }

  public getExpenses(start: string, end: string, userId?: number): Promise<Expense[]> {
    return this.api.jsonGet(`/expenses/range/${start}/${end}`, { userId });
  }
}
