interface PlantCardProps {
  plant: PlantStatusProps & { friendlyName: string; happiness: string; info: { image: string } };
}

interface PlantSensorStatus {
  min: number;
  max: number;
  value: number;
  ok: boolean;
}
interface PlantStatusProps {
  status: {
    [key: string]: PlantSensorStatus;
  };
}
interface SensorStatusProps {
  name: string;
  icon: string;
  unit: string;
  sensor: PlantSensorStatus;
}

const SensorStatus = ({ icon, sensor, unit }: SensorStatusProps) => {
  return (
    <div className={`columns sensor-${sensor.ok}`}>
      <div className="column">
        {icon} {sensor.value}
        {unit}
      </div>
      <div className="column">
        {sensor.min}-{sensor.max}
      </div>
    </div>
  );
};

const PlantStatus = ({ status }: PlantStatusProps) => {
  return (
    <div>
      <SensorStatus name="temperature" sensor={status.temperature} icon="🌡" unit="°C" />
      <SensorStatus name="moisture" sensor={status.moisture} icon="💧" unit="%" />
      <SensorStatus name="conductivity" sensor={status.conductivity} icon="🍗" unit="µS/cm" />
      <SensorStatus name="brightness" sensor={status.brightness} icon="☀️" unit="lx" />
      <SensorStatus name="battery" sensor={status.battery} icon="🔋" unit="%" />
    </div>
  );
};

export const PlantCard = ({ plant }: PlantCardProps) => {
  return (
    <div className="column">
      <div>
        <p className="label">{plant.friendlyName}</p>
        <p className="heading">{plant.happiness}</p>
        <img className={`plant-${plant.happiness}`} alt={plant.friendlyName} src={plant.info.image} />
      </div>
      <PlantStatus status={plant.status} />
    </div>
  );
};
