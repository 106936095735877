import { Weather } from '../../services/OfficeService';

interface WeatherCardProps {
  weather: Weather;
}

interface EntityProps {
  name?: string;
  value?: number | string;
  unit?: string;
}

const Entity = ({ name, value, unit }: EntityProps) => {
  return (
    <div className="column">
      <p className="heading">{name}</p>
      <p className="title">
        {value}
        {unit}
      </p>
    </div>
  );
};

export const WeatherCard = ({ weather }: WeatherCardProps) => {
  return (
    <div className="columns">
      <Entity name="temperature" value={weather.temperature} unit="°C" />
      <Entity name="humidity" value={weather.humidity} unit="%" />
      <Entity name="status" value={weather.status} unit="" />
      <Entity name="rain prediction (until 8pm)" value={weather.forecastMsg} unit="" />
      <Entity />
    </div>
  );
};
