import { ApiService } from './ApiService';
import { Injector } from 'react-service-injector';

export class DownloadService {
  public static readonly NAME = 'DownloadService';
  private readonly api: ApiService;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiService);
  }

  public download(downloadBuffer: Buffer, filename: string) {
    const previewReportBlob = new Blob([downloadBuffer]);
    const downloadURL = window.URL.createObjectURL(previewReportBlob);
    const aTag = document.createElement('a');
    document.body.appendChild(aTag);
    aTag.href = downloadURL;
    aTag.download = `${filename}.pdf`;
    aTag.click();
    aTag.remove();
  }
}
