import { DayPlanning } from '../../services/PlanningService';
import { User } from '../../services/TimeChimpService';
import { ActivityIcon } from './ActivityIcon';
import { ActivityPlanningCell } from './ActivityPlanningCell';

interface Props {
  planning: DayPlanning[];
  users: User[];
}

const activityDescription: Record<string, string> = {
  available: 'kantoor',
  home: 'thuis',
  otherlocation: 'infi',
  extern: 'klant',
  spacer: '',
  leave: 'verlof',
  sick: 'ziek',
  unavailable: 'afwezig',
};

export const ActivityPlanning = ({ planning, users }: Props) => {
  const activities = Object.keys(activityDescription);
  return (
    <tbody>
      {activities.map((activity) =>
        activityDescription[activity] ? (
          <tr key={activity} className={`activity-row row-${activity}`}>
            <th className={activity}>
              <div>
                <ActivityIcon activity={activity} />
              </div>
              <div>{activityDescription[activity]}</div>
            </th>
            <ActivityPlanningCell planning={planning} users={users} weekday={1} activity={activity} />
            <ActivityPlanningCell planning={planning} users={users} weekday={2} activity={activity} />
            <ActivityPlanningCell planning={planning} users={users} weekday={3} activity={activity} />
            <ActivityPlanningCell planning={planning} users={users} weekday={4} activity={activity} />
            <ActivityPlanningCell planning={planning} users={users} weekday={5} activity={activity} />
          </tr>
        ) : (
          <tr key={activity} className="spacer">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        )
      )}
    </tbody>
  );
};
