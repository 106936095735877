import { NavTile } from '../components/NavTile';
import { NavLink, useLocation } from 'react-router-dom';
import { menu, MenuColor } from '../menu';
import { useUserData } from '../hooks/useUserData';

const colors: MenuColor[] = ['blue', 'green', 'yellow', 'red', 'grey'];

export const LandingPage = () => {
  const { firstName } = useUserData();
  const path = useLocation().pathname;
  const menuCategory = menu.find((c) => c.link === path);

  if (!menuCategory) {
    return null;
  }

  const children = menuCategory.children.length ? menuCategory.children : menu.slice(1);

  return (
    <div className="landing-page">
      {menuCategory.link !== '/' ? (
        <h1>
          <i className={`fas fa-${menuCategory.icon}`} />
          {menuCategory.name}
        </h1>
      ) : (
        <>
          <h1>
            <img src="/joost-icon.svg" alt="Joost" />
            Joost
          </h1>
          {/* <h3 className="subtitle">mag het weten</h3> */}
        </>
      )}
      <div className="tiles">
        {children.map((item, i) => (
          <NavTile
            key={item.link}
            color={colors[i % colors.length]}
            icon={item.icon}
            text={item.name}
            link={item.link}
          />
        ))}
      </div>
      <div className="content">
        <h2>Welcome to Joost, {firstName}</h2>
        <p>Joost mag het weten!</p>
        <p>
          Joost is de Infi Nijmegen know-it-all, die antwoord kan geven op veel vragen (en nee, het is geen ChatGPT). In
          Joost vind je de dashboards en metrics die je kunnen vertellen hoe het gaat met de{' '}
          <NavLink to="/office/plants">planten op kantoor</NavLink>, de kwartaaldoelen, de{' '}
          <NavLink to="/team/planning">planning</NavLink>, de <NavLink to="/team/finance">financien</NavLink> van Infi
          Nijmegen en nog veel meer.{' '}
        </p>
        <p>
          Ook heeft Joost een aantal self-service features voor <NavLink to="/personal">medewerkers.</NavLink>
        </p>
        <p>
          Mis je iets? Dan{' '}
          <a href="https://gitlab.com/infi-projects/nijmegen/intern/joost/">voeg je dat lekker zelf toe.</a>
        </p>
      </div>
    </div>
  );
};
