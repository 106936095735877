import { Gallery, GalleryService, Photo } from '../services/GalleryService';
import { useEffect, useState } from 'react';
import { useService } from 'react-service-injector';
import { randomElement, randomIntBetween } from '../utils/random';
import { CalendarEvent, CalendarService } from '../services/CalendarService';
import { Birthday, BirthdayService } from '../services/BirthdayService';
import { formatDateDayMonth } from '../utils/date';

interface ImageWithGallery {
  image: Photo;
  gallery: Gallery;
}

export const GalleryDashboard = () => {
  const galleryService = useService(GalleryService);
  const calendarService = useService(CalendarService);
  const birthdayService = useService(BirthdayService);
  const [current, setCurrent] = useState<ImageWithGallery>();
  const [sloppy, setSloppy] = useState(0);
  const [eventsAndBirthdays, setEventsAndBirthdays] = useState<(CalendarEvent | Birthday)[]>([]);

  useEffect(() => {
    const reload = () => {
      galleryService.getGalleries().then((galleries) => {
        const allImages = galleries.flatMap((g) =>
          g.photos.map(
            (i): ImageWithGallery => ({
              gallery: g,
              image: i,
            })
          )
        );

        if (allImages.length === 0) {
          setCurrent(undefined);
          return;
        }

        setCurrent(randomElement(allImages));
        setSloppy(randomIntBetween(-3, 3));
      });

      Promise.all([calendarService.getFutureEvents(), birthdayService.getFutureBirthdays()]).then(
        ([events, birthdays]) => setEventsAndBirthdays(combineEventsAndBirthdays(events, birthdays).slice(0, 5))
      );
    };
    reload();
    const timer = setInterval(reload, 10000);

    return () => clearInterval(timer);
  }, [birthdayService, calendarService, galleryService]);

  if (!current) {
    return null;
  }

  return (
    <div className="gallery-dashboard content is-flex is-flex-direction-column">
      <div className="image-container has-text-centered is-flex is-flex-direction-column">
        <h1>
          {current.gallery.title} | {current.image.title}
        </h1>
        <div
          className="image is-flex-grow-1"
          style={{
            backgroundImage: `url("${current.image.publicUrl}")`,
            transform: `rotate(${sloppy}deg)`,
          }}
        />
      </div>
      <div className="events">
        {eventsAndBirthdays.map((e, i) => (
          <div key={i} className="event">
            {isCalendarEvent(e) ? (
              <>
                <div className="title is-3">
                  {e.date ? formatDateDayMonth(e.date) : e.humanDate} - {e.description}
                </div>
                <div className="subtitle is-3">
                  {e.time} | {e.who}
                </div>
              </>
            ) : (
              <div className="title is-3">
                {formatDateDayMonth(e.date)} - 🥳 {e.name} 🎉
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

function combineEventsAndBirthdays(events: CalendarEvent[], birthdays: Birthday[]): (CalendarEvent | Birthday)[] {
  const all = [...events, ...birthdays];
  all.sort((a, b) => {
    if (a.date && b.date) {
      return a.date.localeCompare(b.date);
    }
    if (!a.date && !b.date) {
      return 0;
    }
    return a.date ? -1 : 1;
  });
  return all;
}

function isCalendarEvent(item: CalendarEvent | Birthday): item is CalendarEvent {
  return 'humanDate' in item;
}
