import { ApiService } from './ApiService';
import { TokenApiService } from './TokenApiService';

export interface WeekSummaryDto {
  billablePercent: number;
  marginPercent: number;
  profitProgress: number;
  projectHours: Record<string, number>;
}

export class DashboardService {
  private readonly api: ApiService = new TokenApiService();

  public getWeekSummary(): Promise<WeekSummaryDto> {
    return this.api.jsonGet('/dashboards/week');
  }
}
