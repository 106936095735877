import { Injector } from 'react-service-injector';
import { ApiService } from './ApiService';

export interface UserPlanning {
  email: string;
  year: number;
  week: number;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
}

export interface DayPlanning {
  email: string;
  location: string;
  year: number;
  week: number;
  weekday: number;
  date: string;
  planning: string;
}

export class PlanningService {
  private readonly api: ApiService;

  constructor(injector: Injector) {
    this.api = injector.resolve(ApiService);
  }

  public getWeekPlanning(year: number, week: number): Promise<UserPlanning[]> {
    return this.api.jsonGet<UserPlanning[]>(`/planning/${year}/${week}`);
  }

  public getLocationPlanning(location: string, start: string, end: string): Promise<DayPlanning[]> {
    return this.api.jsonGet<DayPlanning[]>(`/planning/location/${location}/${start}/${end}`);
  }

  public getUserPlanning(email: string, start: string, end: string): Promise<DayPlanning[]> {
    return this.api.jsonGet<DayPlanning[]>(`/planning/user/${email}/${start}/${end}`);
  }
}
