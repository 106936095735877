import { Result } from '../services/TimeChimpService';

const Currency = ({ children }: { children?: number }) => (
  <>
    {children?.toLocaleString('nl-NL', {
      style: 'currency',
      currency: 'EUR',
    }) || <i className="fas fa-spin fa-refresh" />}
  </>
);

export interface Props {
  results?: Result;
}

export const FinancialResults = ({ results }: Props) => (
  <div className="columns">
    <div className="column">
      <div>
        <p className="heading">Revenue</p>
        <p className="title">
          <Currency>{results?.revenue}</Currency>
        </p>
      </div>
    </div>
    <div className="column">
      <p className="heading">Costs</p>
      <p className="title">
        <Currency>{results?.costs}</Currency>
      </p>
    </div>
    <div className="column">
      <p className="heading">Margin</p>
      <p className="title">
        {results?.marginPercentage ? (
          `${results.marginPercentage.toFixed(1)} %`
        ) : (
          <i className="fas fa-spin fa-refresh" />
        )}
      </p>
    </div>
    <div className="column">
      <p className="heading">Hourly rate</p>
      <p className="title">
        <Currency>{results?.hourlyRate}</Currency>
      </p>
    </div>
  </div>
);
