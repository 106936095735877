import { DayPlanning } from '../../services/PlanningService';
import { User } from '../../services/TimeChimpService';

interface Props {
  planning: DayPlanning[];
  users: User[];
  weekday: number;
  activity: string;
}

export const ActivityPlanningCell = ({ planning, users, weekday, activity }: Props) => (
  <td>
    {planning
      .filter((planning) => planning.weekday === weekday && planning.planning === activity)
      .map((planning) => {
        const user = users.find((user) => planning.email === user.userName) ?? {
          displayName: planning.email,
        };
        return <div key={user.displayName}>{user.displayName}</div>;
      })}
  </td>
);
