import { useService } from 'react-service-injector';
import { Expense, ExpensesService } from '../services/ExpensesService';
import { useEffect, useState } from 'react';
import { DateRangeSelector, Range } from '../components/DateRangeSelector';
import { useCurrentUser, useUsers } from '../hooks/useUserData';
import { PeopleSelector } from '../components/PeopleSelector';
import { ExpenseCard } from '../components/ExpenseCard';
import { User } from '../services/TimeChimpService';

export const ExpensesPage = () => {
  const users = useUsers();
  const expensesService = useService(ExpensesService);

  const [range, setRange] = useState<Range>({});
  const [selectedUser, setSelectedUser] = useState<User | undefined>(useCurrentUser());
  const [expenses, setExpenses] = useState<Expense[]>([]);

  useEffect(() => {
    if (!users || !range.start || !range.end) {
      return;
    }

    expensesService.getExpenses(range.start, range.end, selectedUser?.id).then((expenses) => {
      const filteredExpenses: Expense[] = expenses.filter((expense) =>
        users.find((user) => user.id === expense.userId)
      );
      setExpenses(filteredExpenses);
    });
  }, [expensesService, range.end, range.start, selectedUser?.id, users]);

  return (
    <>
      <div className="columns">
        <div className="column">
          <h1>Expenses</h1>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <PeopleSelector selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
        </div>
        <DateRangeSelector range={range} onRangeChange={setRange} />
      </div>
      {expenses.map((e) => (
        <ExpenseCard key={e.id} expense={e} />
      ))}
    </>
  );
};
