import { useService } from 'react-service-injector';
import { useCallback, useEffect, useState } from 'react';
import { DateRangeSelector, Range } from '../components/DateRangeSelector';
import { useCurrentUser, useUsers } from '../hooks/useUserData';
import { User } from '../services/TimeChimpService';
import { PeopleSelector } from '../components/PeopleSelector';
import { MileageCard } from '../components/MileageCard';
import { Mileage, MileageService, UpdateMileage } from '../services/MileageService';
import { Customer, CustomersService } from '../services/CustomerImportService';
import { ReportService } from '../services/ReportService';
import { DownloadService } from '../services/DownloadService';

interface MileageAndStatus {
  mileage: Mileage;
  needsCheck: boolean;
}

export const MileagePage = () => {
  const users = useUsers();
  const mileageService = useService(MileageService);
  const customerService = useService(CustomersService);
  const reportService = useService(ReportService);
  const downloadService = useService(DownloadService);
  const [range, setRange] = useState<Range>({});
  const [selectedUser, setSelectedUser] = useState<User | undefined>(useCurrentUser());
  const [mileage, setMileage] = useState<MileageAndStatus[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [previewButtonEnabled, setPreviewButtonEnabled] = useState<boolean>(true);
  const [mileagePreviewed, setMileagePreviewed] = useState<boolean>(false);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState<boolean>(false);
  const [allMileagesComplyWithRules, setAllMileagesComplyWithRules] = useState<boolean>(true);
  const [explanationText, setExplanationText] = useState<string>('');
  const [explanationTexStyle, setExplanationTextStyle] = useState<string>('explanation-text-warning');

  const updateMileages = useCallback(
    (start: string, end: string) => {
      mileageService.getMileage(start, end, selectedUser?.id).then((mileages) => {
        const filteredMileages: Mileage[] = mileages.filter((mileage) =>
          users.find((user) => user.id === mileage.userId)
        );

        const newMileages = filteredMileages.map((m) => ({
          mileage: m,
          needsCheck: !mileageService.appliesToBusinessRules(m) && m.status !== 3,
        }));

        setAllMileagesComplyWithRules(!newMileages.find((m) => m.needsCheck));
        setMileage(newMileages);
      });
    },
    [mileageService, selectedUser?.id, users]
  );

  const shouldEnablePreviewButton = useCallback(() => {
    if (!range.start || !range.end) return false;

    const startMonth = range.start.substring(5, range.start.lastIndexOf('-'));
    const endMonth = range.end.substring(5, range.end.lastIndexOf('-'));
    const now = new Date();
    const currentMonth = ('0' + (now.getMonth() + 1)).slice(-2);
    const currentDate = now.toISOString().substring(0, now.toISOString().lastIndexOf('T'));
    //TODO add extra check for submitting current year / when end date is past current date
    if (!selectedUser) {
      setExplanationText('Are you santa? You cannot submit mileages for everyone at the same time!');
      setExplanationTextStyle('notification has-text-white explanation-text-warning');
      return false;
    }
    if (currentMonth === startMonth || currentMonth === endMonth) {
      setExplanationText('Not so quick! You cannot submit mileages for the current month!');
      setExplanationTextStyle('notification has-text-white explanation-text-warning');
      return false;
    }
    if (currentDate < range.end) {
      setExplanationText('Are you a fortune teller? you cannot submit mileages for future dates!');
      setExplanationTextStyle('notification has-text-white explanation-text-warning');
      return false;
    }
    const openMileages = mileage.filter((m) => m.mileage.status === 0);
    if (openMileages.length === 0) {
      setExplanationText('No mileages to submit, well done!');
      setExplanationTextStyle('notification explanation-text-correct');
      return false;
    }
    if (!allMileagesComplyWithRules) {
      setExplanationText("Don't be naughty! Not all mileages comply with the business rule, check them!");
      setExplanationTextStyle('notification has-text-white explanation-text-warning');
      return false;
    }

    setExplanationText('Absolute legend! You can preview your unsubmitted mileages!');
    setExplanationTextStyle('notification explanation-text-correct');
    return true;
  }, [allMileagesComplyWithRules, mileage, range.end, range.start, selectedUser]);

  useEffect(() => {
    if (users.length === 0 || !range.start || !range.end) {
      return;
    }
    updateMileages(range.start, range.end);
    customerService.getCustomers().then(setCustomers);
    setMileagePreviewed(false);
  }, [customerService, range.end, range.start, updateMileages, users.length]);

  useEffect(() => {
    setPreviewButtonEnabled(shouldEnablePreviewButton());
  }, [shouldEnablePreviewButton]);

  useEffect(() => {
    if (previewButtonEnabled && mileagePreviewed) {
      setExplanationText('Even better: You can submit your mileages!');
      setExplanationTextStyle('notification explanation-text-correct');
      setSubmitButtonEnabled(true);
    } else {
      setSubmitButtonEnabled(false);
    }
  }, [previewButtonEnabled, mileagePreviewed]);

  async function getMileageReport(isPreview: boolean) {
    if (!users || !range.start || !range.end) {
      return;
    }
    const report = await reportService.getMileageReport(range.start, range.end, isPreview, selectedUser);
    let filename: string;
    if (isPreview) {
      filename = `PREVIEW-REIS-${selectedUser?.displayName}-${range.start.substring(0, range.start.lastIndexOf('-'))}`;
    } else {
      filename = `REIS-${selectedUser?.displayName}-${range.start.substring(0, range.start.lastIndexOf('-'))}`;
    }
    downloadService.download(report, filename);
  }

  async function changeSubmittedMileages() {
    if (!users || !range.start || !range.end) {
      return;
    }
    const mileageIds = mileage.filter((m) => m.mileage.status !== 3).map((m) => m.mileage.id);
    const toBeUpdatedMileages: UpdateMileage = {
      registrationIds: mileageIds,
      status: 3,
    };
    await mileageService.updateMileages(toBeUpdatedMileages);
    await updateMileages(range.start, range.end);
  }

  function PreviewAndSubmitElement() {
    return (
      <div className="buttons is-right ">
        <div className={explanationTexStyle}> {explanationText} </div>
        <button
          className="button preview-button mx-1"
          disabled={!previewButtonEnabled}
          onClick={() => {
            getMileageReport(true);
            setMileagePreviewed(true);
          }}
        >
          Preview
        </button>
        <button
          className="button submit-button mx-1"
          disabled={!submitButtonEnabled}
          onClick={() => {
            getMileageReport(false);
            changeSubmittedMileages();
          }}
        >
          Submit
        </button>
      </div>
    );
  }

  return (
    <>
      <div className="columns">
        <div className="column">
          <h1>Mileage</h1>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <PeopleSelector selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
        </div>
        <DateRangeSelector range={range} onRangeChange={setRange} />
      </div>
      <PreviewAndSubmitElement />
      {mileage.map((m) => (
        <MileageCard key={m.mileage.id} mileage={m.mileage} customers={customers} needsCheck={m.needsCheck} />
      ))}
      <PreviewAndSubmitElement />
    </>
  );
};
