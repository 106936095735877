import { Route, Routes } from 'react-router-dom';
import { WeekSummaryDashboard } from './dashboards/WeekSummaryDashboard';
import { GalleryDashboard } from './dashboards/GalleryDashboard';
import { useEffect, useState } from 'react';
import { DashboardService, WeekSummaryDto } from './services/DashboardService';
import { useService } from 'react-service-injector';
import { Modal } from './components/Modal';

export const Dashboard = () => {
  const service = useService(DashboardService);
  const [summary, setSummary] = useState<WeekSummaryDto>();
  const [errored, setErrored] = useState(false);

  useEffect(() => {
    function loadData() {
      setErrored(false);
      service
        .getWeekSummary()
        .then(setSummary)
        .catch(() => {
          setErrored(true);
        });
    }

    loadData();

    // reload the data every minute
    const timer = setInterval(loadData, 60000);

    return () => clearInterval(timer);
  }, [service]);

  useEffect(() => {
    // hard refresh after one hour
    const timeout = setTimeout(() => {
      window.location.reload();
    }, 60 * 60 * 1000);
    return () => clearTimeout(timeout);
  }, []);

  if (!summary) {
    return (
      <Modal>
        <div className="has-text-centered">
          {errored ? (
            <i className="title fas fa-exclamation-triangle" />
          ) : (
            <i className="title fas fa-spin fa-refresh" />
          )}
        </div>
      </Modal>
    );
  }

  return (
    <div className="dashboard-page">
      <Routes>
        <Route path="week" element={<WeekSummaryDashboard summary={summary} />} />
        <Route path="gallery" element={<GalleryDashboard />} />
      </Routes>
    </div>
  );
};
