import { DateTime } from 'luxon';
import { Item, Task } from './AlpacaService';
import { CreateTime } from './TimeChimpService';
import { Identifiable } from '../utils/Identifiable';

export interface ProjectTask {
  id: number;
  projectId: number;
  taskId: number;
  taskName: string;
}

export class ProjectImportService {
  findRelatedProject<T extends Identifiable>(sourceProject: Identifiable, targetProjects: T[]) {
    const sourceName = sourceProject.name.toLowerCase().endsWith(' - infi nijmegen')
      ? sourceProject.name.substring(0, sourceProject.name.length - 16)
      : sourceProject.name;

    const normalizedSourceName = sourceName.toLowerCase().replace(/\s/g, '');
    return (
      targetProjects?.find((target) => target.name.toLowerCase().replace(/\s/g, '') === normalizedSourceName) ||
      targetProjects?.find(
        (target) => target.customerName?.toLowerCase().replace(/\s/g, '') === normalizedSourceName
      ) ||
      targetProjects?.find(
        (target) =>
          target.customerName &&
          (target.customerName.toLowerCase().replace(/\s/g, '').startsWith(normalizedSourceName) ||
            normalizedSourceName.startsWith(target.customerName.toLowerCase().replace(/\s/g, '')))
      )
    );
  }

  public transform(
    source: Item[],
    mapping: Record<number, ProjectTask>,
    options: { defaultUserId: number; users: Record<number, number> }
  ): { source: Item; target: CreateTime }[] {
    const { defaultUserId, users } = options;
    return source
      .map((item): { source: Item; target: CreateTime } | undefined => {
        const sourceUserId = Number(item.userId) ?? 0;
        const userId = users[sourceUserId] ?? defaultUserId;

        const taskId = !item.billable ? -1 : Number(item.taskId) ?? 0;

        if (mapping[taskId]) {
          const projectTaskId = mapping[taskId].id;
          const projectId = mapping[taskId].projectId;

          const hours = item.duration;
          const date = DateTime.fromFormat(item.datetime, 'yyyy-MM-dd HH:mm');
          const notes = userId === defaultUserId ? `[${item.userName}] ${item.description}` : item.description;
          const start = date;
          const end = date.plus({ hours });

          return {
            source: item,
            target: {
              projectId,
              projectTaskId,
              userId,
              notes,
              date: date.toFormat('yyyy-MM-dd') + 'T00:00:00',
              hours,
              start: start.toUTC().toISO() || '',
              end: end.toUTC().toISO() || '',
              startEnd:
                start.toUTC().toLocal().toFormat('HH:mm') + '-' + end.toUTC().toLocal().toFormat('HH:mm') + '    ',
            },
          };
        }
        return undefined;
      })
      .filter((item): item is Exclude<undefined, typeof item> => !!item);
  }

  private findRelatedTask(source: Identifiable, targets: ProjectTask[]): ProjectTask | undefined {
    if (!source) {
      return undefined;
    }
    if (source.id === -1) {
      return targets.find((task) => task.taskName === 'Intern');
    }
    const normalizedSourceName = source.name.toLowerCase().replace(/\s/g, '');
    return (
      targets.find((task) => task.taskName.toLowerCase() === normalizedSourceName) ||
      targets.find((task) => {
        const normalizedTargetName = task.taskName.toLowerCase().replace(/\s/g, '');
        return (
          normalizedTargetName.startsWith(normalizedSourceName) ||
          normalizedSourceName.toLowerCase().startsWith(normalizedTargetName)
        );
      })
    );
  }

  createTaskMappings(sourceTasks: Task[], targetTasks: ProjectTask[]) {
    return sourceTasks.reduce(
      (accu, source) => ({
        ...accu,
        [source.id]: this.findRelatedTask(source, targetTasks)?.id || 0,
      }),
      { [-1]: this.findRelatedTask({ id: -1, name: 'intern' }, targetTasks)?.id || 0 }
    );
  }
}
