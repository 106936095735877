import { User } from '../services/TimeChimpService';
import { useUsers } from '../hooks/useUserData';

export interface Props {
  selectedUser?: User;
  setSelectedUser: (user: User | undefined) => void;
}

export const PeopleSelector = ({ selectedUser, setSelectedUser }: Props) => {
  const users = useUsers();

  return (
    <div className="field">
      <label className="label">People</label>
      <div className="select">
        <select
          className="select"
          value={selectedUser?.id}
          onChange={(e) => setSelectedUser(users.find((u) => u.id.toString() === e.target.value))}
        >
          <option>Everyone</option>
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.displayName}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
