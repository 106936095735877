import Highcharts from 'highcharts';

export const sharedBarChartOptions: Highcharts.Options = {
  accessibility: {
    announceNewData: {
      enabled: true,
    },
    point: {
      valueSuffix: 'h',
    },
  },
  plotOptions: {
    line: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  tooltip: {
    headerFormat: '<span>{series.name}</span><br>',
    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.f}h</b><br/>',
  },
  xAxis: {
    type: 'category',
  },
  yAxis: {
    title: {
      text: 'Hours',
    },
  },
  chart: {
    backgroundColor: 'transparent',
    style: {
      fontFamily:
        '"Open Sans", ui-sans-serif, system-ui, -apple-system, "system-ui", "Segoe UI", "Roboto", "Helvetica Neue"',
    },
  },
};

export const colors = {
  orange: '#f77803',
  blue: '#269bc9',
  red: '#cc3333',
  yellow: '#fac912',
  green: '#8cbf33',
  black: '#12121c',
};

export const fallbackColors = Object.values(colors);

export const seriesColors: Record<string, string> = {
  Billable: colors.green,
  'Non billable': colors.red,
  'Administratie & Finance': colors.orange,
  Bedrijfsmeetings: 'amber',
  HR: colors.yellow,
  IT: 'lime',
  Marketing: 'emerald',
  'MT & Directie': 'teal',
  'Office Management': colors.blue,
  Overig: 'lightgrey',
  'Persoonlijke Administratie': 'indigo',
  'Persoonlijke Ontwikkeling': colors.orange,
  Sales: 'purple',
  Werving: 'fuchsia',
  'Bijzonder verlof': 'coolGray',
  Verlof: 'gray',
  Ziek: 'warmGray',
};
