import { ApiService } from './ApiService';
import { Injector } from 'react-service-injector';

export interface UpdateMileage {
  registrationIds: number[];
  status: number;
}

export interface Mileage {
  id: number;
  userId: number;
  customerName: string;
  projectName: string;
  fromAddress: string;
  toAddress: string;
  date: string;
  billable: boolean;
  distance: number;
  status: number;
  type: number;
}

export enum MileageStatus {
  open = 0,
  invoiced = 3,
}

export class MileageService {
  public static readonly NAME = 'MileageService';

  private readonly api: ApiService;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiService);
  }

  public getMileage(start: string, end: string, userId?: number): Promise<Mileage[]> {
    return this.api.jsonGet(`/mileage/range/${start}/${end}`, { userId });
  }

  public async updateMileages(toUpdateMileages: UpdateMileage) {
    return await this.api.jsonPost<UpdateMileage, UpdateMileage>('/mileage/updateMileage', toUpdateMileages);
  }

  public appliesToBusinessRules(m: Mileage) {
    return (
      (m.customerName.includes(`Infi Nijmegen`) && !m.billable) ||
      (!m.customerName.includes(`Infi Nijmegen`) && m.billable)
    );
  }
}
