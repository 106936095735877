import { formatDateLong } from '../utils/date';
import { useUser } from '../hooks/useUserData';
import { AbsenceDto } from '../services/AbsencesService';
import { DayPlanning } from '../services/PlanningService';

export interface Props {
  absence: AbsenceDto;
  buzzy?: DayPlanning;
}

export const AbsenceCard = ({ absence, buzzy }: Props) => {
  const user = useUser(absence.userId);
  const className = !buzzy
    ? 'error-line'
    : buzzy.planning === 'leave' || buzzy.planning === 'unavailable'
    ? 'ok-line'
    : 'warning-line';
  return (
    <div className={'box ' + className}>
      <div className="columns">
        <div className="column">
          <div className="has-text-weight-bold">{formatDateLong(absence.startDate)}</div>
          <div>{user.displayName}</div>
        </div>
        <div className="column">
          <div>{absence.taskName}</div>
          <div>{buzzy && '(' + buzzy.planning + ')'}</div>
        </div>
        <div className="column">
          <div>{absence.times.reduce((total, time) => total + time.hours, 0)}</div>
        </div>
      </div>
    </div>
  );
};
