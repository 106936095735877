export interface Time {
  date: string;
  description: string;
  time: number;
  warning?: boolean;
}

export interface Props {
  times: (Time | undefined)[];
}

export const TaskList = ({ times }: Props) => (
  <div className="task-list">
    {times.map((task, i) => (
      <div className="task" key={i}>
        {task ? (
          <>
            <span className="date">{task.date.substring(0, 10)}</span>
            <span className={`description ${task.warning ? 'has-text-danger' : ''}`}>{task.description}</span>
            <span className="time">{task.time}</span>
          </>
        ) : (
          <span className="has-text-danger">Duplicate</span>
        )}
      </div>
    ))}
  </div>
);
