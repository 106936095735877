import { Expense, expenseStatus } from '../services/ExpensesService';
import { formatDateLong } from '../utils/date';
import { formatEuro } from '../utils/euro';
import { useUser } from '../hooks/useUserData';

export interface Props {
  expense: Expense;
}

export const ExpenseCard = ({ expense }: Props) => {
  const user = useUser(expense.userId);

  return (
    <div className="box">
      <div className="columns">
        <div className="column">
          <div className="has-text-weight-bold">{formatDateLong(expense.date)}</div>
          <div>{user.displayName}</div>
        </div>
        <div className="column">
          <div>
            {expense.customerName} ({expense.projectName})
          </div>
          <div>{expense.categoryName}</div>
        </div>
        <div className="column">
          <div>{formatEuro(expense.rate)}</div>
          <div>{expense.tax}% VAT</div>
        </div>
        <div className="column tags are-medium">
          {expense.billable && <span className="tag">Billable</span>}
          {expense.status === expenseStatus.invoiced && <span className="tag is-warning">Invoiced</span>}
        </div>
      </div>
    </div>
  );
};
