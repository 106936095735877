import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { fallbackColors, seriesColors, sharedBarChartOptions } from './options';

export interface Bar {
  name: string;
  value: number;
}

export interface Props {
  title: string;
  header: string;
  bars: Bar[];
}

export const SingleStatBarChart = ({ bars, title, header }: Props) => {
  const options: Highcharts.Options = {
    ...sharedBarChartOptions,
    series: [
      {
        name: header,
        type: 'column',
        data: bars.map((bar, index) => ({
          name: bar.name,
          y: bar.value,
          color: seriesColors[bar.name] || Object.values(fallbackColors)[index % fallbackColors.length],
        })),
      },
    ],
    title: { text: `<h1>${title}</h1>` },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
