import { useEffect, useState } from 'react';
import { useService } from 'react-service-injector';
import { HealthCheck, HealthCheckFile, HealthService } from '../services/HealthService';
import { DateTime } from 'luxon';
import { Results, TimeChimpService, TimeDto } from '../services/TimeChimpService';
import { useUsers } from '../hooks/useUserData';

interface TitleAndValueProps {
  title: string;
  valueAvailable: boolean;
  value?: string;
}

const TitleAndPercentageValue = ({ title, valueAvailable, value }: TitleAndValueProps) => (
  <>
    <h3>{title}</h3>
    {valueAvailable ? (
      <>
        <div className="value">{value} %</div>
      </>
    ) : (
      <i className="fas fa-spin fa-refresh" />
    )}
  </>
);

interface Props {
  startDate: DateTime;
  endDate: DateTime;
  healthDate: DateTime;
}
export const KpiColumn = ({ startDate, endDate, healthDate }: Props) => {
  const healthService = useService(HealthService);
  const timesService = useService(TimeChimpService);
  const users = useUsers();
  const [times, setTimesSevenDays] = useState<TimeDto[]>();
  const [files, setFiles] = useState<HealthCheckFile[]>();
  const [currentHealth, setCurrentHealth] = useState<HealthCheck>();
  const [results, setResults] = useState<Results>();
  const includeLeave = false;
  const includeSick = true;
  const [nonBillable, setNonBillable] = useState<number[]>([]);
  const [billable, setBillable] = useState<number[]>([]);

  useEffect(() => {
    const getTimes = async () => {
      try {
        const newTimes = (
          await timesService.getTimes(startDate.toFormat('yyyy-MM-dd'), endDate.toFormat('yyyy-MM-dd'), {
            includeLeave,
            includeSick,
          })
        ).filter((time: { userId: number }) => users.some((user) => user.id === time.userId));
        setTimesSevenDays(
          newTimes.filter((entry: { userId: number }) => !!users.find((user) => user.id === entry.userId))
        );
      } catch (err) {
        console.error('Could not get times', err);
      }
    };
    getTimes();
  }, [timesService, startDate, endDate, users, includeLeave, includeSick]);

  useEffect(() => {
    if (!times) {
      return;
    }

    const billables: Record<string, number> = {};
    const nonBillables: Record<string, number> = {};

    times.forEach((time) => {
      if (timesService.isNonBillable(time)) {
        nonBillables[time.taskName] = (nonBillables[time.taskName] || 0) + time.hours;
      } else {
        billables[time.projectName] = (billables[time.projectName] || 0) + time.hours;
      }
    });

    setBillable(Object.entries(billables).map(([_, hours]) => hours));

    setNonBillable(Object.entries(nonBillables).map(([_, hours]) => hours));
  }, [times, timesService]);

  const billableHours = billable.reduce((prev, next) => prev + next, 0);
  const nonBillableHours = nonBillable.reduce((prev, next) => prev + next, 0);

  useEffect(() => {
    healthService.listHealthChecks().then(setFiles);
  }, [healthService]);

  useEffect(() => {
    if (files) {
      setCurrentHealth(undefined);

      const fileIndex = files.findIndex((file) => file.date === healthDate.toFormat('yyyy-MM-dd'));
      if (fileIndex > -1) {
        healthService.getHealthCheck(files[fileIndex].id).then(setCurrentHealth);
      }
    }
  }, [healthDate, files, healthService]);

  useEffect(() => {
    (async () => {
      setResults(undefined);

      const results = await timesService.getResultsForDate(startDate, endDate);

      setResults(results);
    })();
  }, [startDate, endDate, timesService]);

  return (
    <div>
      <TitleAndPercentageValue
        title={'Health ' + healthDate.toFormat('yyyy-MM-dd')}
        valueAvailable={!!currentHealth && !!healthDate}
        value={currentHealth?.healthPercentage.toFixed(0)}
      />
      <TitleAndPercentageValue
        title="Margin"
        valueAvailable={!!results}
        value={results?.internal.marginPercentage.toFixed(0)}
      />
      <TitleAndPercentageValue
        title="Billability"
        valueAvailable={!!billableHours && !!nonBillableHours}
        value={((100 * billableHours) / (nonBillableHours + billableHours)).toFixed(0)}
      />
    </div>
  );
};
