import { PropsWithChildren } from 'react';

export interface Props {
  active?: boolean;
  onClose?: () => void;
}

export const Modal = ({ onClose, active, children }: PropsWithChildren<Props>) => (
  <div className={`modal ${active === false ? '' : 'is-active'} `}>
    <div className="modal-background" onClick={onClose} />
    <div className="modal-content">{children}</div>
    {onClose && <button className="modal-close is-large" aria-label="close" onClick={onClose}></button>}
  </div>
);
