import { DayPlanning } from '../../services/PlanningService';
import { User } from '../../services/TimeChimpService';
import { ActivityIcon } from './ActivityIcon';
import { UserPlanningCell } from './UserPlanningCell';

interface Props {
  planning: DayPlanning[];
  users: User[];
}

export const activityDescription: Record<string, string> = {
  available: 'kantoor',
  home: 'thuis',
  otherlocation: 'infi',
  extern: 'klant',
  leave: 'verlof',
  sick: 'ziek',
  unavailable: 'afwezig',
};

export const UserPlanning = ({ planning, users }: Props) => {
  return (
    <>
      <tbody>
        {users.map((user) => (
          <tr key={user.userName} className={`activity-row`}>
            <th>{user.displayName}</th>
            <UserPlanningCell planning={planning} weekday={1} userName={user.userName} />
            <UserPlanningCell planning={planning} weekday={2} userName={user.userName} />
            <UserPlanningCell planning={planning} weekday={3} userName={user.userName} />
            <UserPlanningCell planning={planning} weekday={4} userName={user.userName} />
            <UserPlanningCell planning={planning} weekday={5} userName={user.userName} />
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th align="right">Legenda</th>
          <th colSpan={5}>
            {Object.entries(activityDescription).map(([activity, description]) => (
              <span key={activity} className={`legend  ${activity}`}>
                <ActivityIcon activity={activity} />: {description}
              </span>
            ))}
          </th>
        </tr>
      </tfoot>
    </>
  );
};
