import { useService } from 'react-service-injector';
import { AbsenceDto, AbsenceService } from '../services/AbsencesService';
import { useEffect, useState } from 'react';
import { DateRangeSelector, Range } from '../components/DateRangeSelector';
import { useCurrentUser } from '../hooks/useUserData';
import { User } from '../services/TimeChimpService';
import { PeopleSelector } from '../components/PeopleSelector';
import { AbsenceCard } from '../components/AbsenceCard';
import { DayPlanning, PlanningService } from '../services/PlanningService';
import { AbsenceInput } from '../components/AbsenceInput';

export const AbsencesPage = () => {
  const currentUser = useCurrentUser();
  const absencesService = useService(AbsenceService);
  const planningService = useService(PlanningService);
  const [range, setRange] = useState<Range>({});
  const [selectedUser, setSelectedUser] = useState<User | undefined>(currentUser);
  const [absences, setAbsences] = useState<AbsenceDto[]>([]);
  const [planning, setPlanning] = useState<DayPlanning[]>([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!range.start || !range.end) {
      return;
    }

    absencesService.getAbsences(range.start, range.end, selectedUser?.id).then(setAbsences);
  }, [absencesService, range.end, range.start, selectedUser?.id]);

  useEffect(() => {
    if (selectedUser) {
      if (!range.start || !range.end) {
        return;
      }
      planningService.getUserPlanning(selectedUser.userName, range.start, range.end).then(setPlanning);
    }
  }, [planningService, selectedUser, range.end, range.start]);

  return (
    <>
      <div className="columns">
        <div className="column">
          <h1>Absences</h1>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <PeopleSelector selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
        </div>
        <DateRangeSelector range={range} onRangeChange={setRange} />
      </div>
      {absences.map((a, index) => {
        const p = planning.find((planning) => a.startDate === planning.date);
        return <AbsenceCard key={index} absence={a} buzzy={p} />;
      })}
      {showModal && (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Absentie toevoegen</p>
              <button className="delete" aria-label="close" onClick={() => setShowModal(false)}></button>
            </header>
            <section className="modal-card-body">
              <AbsenceInput />
            </section>
            <footer className="modal-card-foot">
              <button className="button is-info">Save changes</button>
              <button className="button" onClick={() => setShowModal(false)}>
                Cancel
              </button>
            </footer>
          </div>
        </div>
      )}
      {/* {selectedUser && currentUser && selectedUser.id === currentUser.id && (
        <button onClick={() => setShowModal(true)} className="button is-info">
          +
        </button>
      )} */}
    </>
  );
};
