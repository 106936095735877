import { Injector } from 'react-service-injector';

export type Params = Record<string, string | number | undefined>;

export class ApiService {
  private readonly defaultHeaders: Record<string, string>;

  constructor(injector: Injector | Record<string, string>) {
    if (injector instanceof Injector) {
      this.defaultHeaders = {
        Accept: 'application/json',
      };
    } else {
      this.defaultHeaders = {
        Accept: 'application/json',
        ...injector,
      };
    }
  }

  private static async handleResponse<T>(resPromise: Promise<Response>): Promise<T> {
    const response = await resPromise;
    const responseBody = response.status === 204 ? undefined : await response.json();

    if (response.status >= 400) {
      throw responseBody;
    }
    return responseBody;
  }

  private static getUrl(path: string, params?: Params): string {
    const stringParams: Record<string, string> = {};
    if (params) {
      Object.entries(params).forEach(([k, v]) => {
        if (v != null) {
          stringParams[k] = `${v}`;
        }
      });
    }

    const paramString = params ? `?${new URLSearchParams(stringParams)}` : '';
    return `/api${path}${paramString}`;
  }

  private jsonRequest<T>(
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    path: string,
    body?: unknown,
    params?: Params
  ): Promise<T> {
    const headers = this.defaultHeaders;
    if (body) {
      headers['Content-Type'] = 'application/json';
    }

    return ApiService.handleResponse(
      fetch(ApiService.getUrl(path, params), {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
      })
    );
  }

  public jsonGet<T>(path: string, params?: Params): Promise<T> {
    return this.jsonRequest('GET', path, undefined, params);
  }

  public jsonPost<T, S>(path: string, body: T, params?: Params): Promise<S> {
    return this.jsonRequest<S>('POST', path, body, params);
  }

  public jsonDelete<T, S>(path: string, body: T, params?: Params): Promise<S> {
    return this.jsonRequest<S>('DELETE', path, body, params);
  }
}
