import { NavLink } from 'react-router-dom';
import { MenuColor } from '../menu';

interface MenuLinkProps {
  icon: string;
  text: string;
  link: string;
  color: MenuColor;
}

export const NavTile = ({ icon, text, link, color }: MenuLinkProps) => (
  <NavLink to={link} className={`nav-tile bg-${color}`}>
    <i className={`fas fa-${icon}`} />
    <div className="text">{text}</div>
  </NavLink>
);
