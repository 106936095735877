import { WeekSummaryDto } from '../services/DashboardService';
import React from 'react';
import { BillabilityChart } from '../charts/BillabilityChart';
import { SeriesBarChart } from '../charts/SeriesBarChart';
import Confetti from 'react-confetti';
import { useWindowSize } from '../hooks/useWindowSize';

export interface Props {
  summary: WeekSummaryDto;
}

export const WeekSummaryDashboard: React.FC<Props> = ({ summary }) => {
  const { width, height } = useWindowSize();

  return (
    <>
      {summary.marginPercent > 15 && <Confetti width={width} height={height} />}
      <div className="columns">
        <div className="column">
          <BillabilityChart billablePercent={summary.billablePercent} />
        </div>
        <div className="column">
          <SeriesBarChart
            title="Projects"
            series={[
              {
                name: 'Projects',
                value: Object.entries(summary.projectHours).map(([project, hours]) => ({
                  name: project,
                  y: hours,
                })),
              },
            ]}
          />
        </div>
      </div>
      <div>
        {summary.marginPercent < 0 ? (
          <>
            <h2 className=" has-text-centered title">Progress: {summary.profitProgress.toFixed(1)} %</h2>
            <progress className="progress is-large is-link" value={summary.profitProgress} max="115">
              {summary.profitProgress.toFixed(1)} %
            </progress>
          </>
        ) : (
          <>
            <h2 className=" has-text-centered title">Margin: {summary.marginPercent.toFixed(1)} %</h2>
            <progress className="progress is-large is-link" value={Math.round(summary.marginPercent) + 100} max="115">
              {summary.marginPercent.toFixed(1)} %
            </progress>
          </>
        )}
      </div>
    </>
  );
};
