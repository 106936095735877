import { Link, NavLink } from 'react-router-dom';
import React, { useCallback, useState } from 'react';
import { Modal } from './Modal';
import { menu } from '../menu';
import { classNames } from '../utils/classNames';

type DashboardType = 'week' | 'gallery';

export const NavMenu = () => {
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(false);
  const [dashboardTarget, setDashboardTarget] = useState<DashboardType>('week');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);

  const closeMenu = useCallback(() => setOpen(false), []);

  return (
    <>
      <nav className={classNames('nav-menu', open && 'open')}>
        <div className="top-bar">
          <Link className="logo" to="/">
            <img src="/joost-icon.svg" alt="Joost" />
          </Link>
          <button className="menu-toggle" onClick={() => setOpen((prevState) => !prevState)}>
            <i className={classNames('fas fa-2xl', open ? 'fa-xmark' : 'fa-bars')} />
          </button>
        </div>
        <div className="items">
          {menu.map((category) => (
            <React.Fragment key={category.link}>
              <MenuLink
                icon={category.icon}
                color={category.color}
                text={category.name}
                link={category.link}
                closeMenu={closeMenu}
              />
              {category.children.length > 0 && (
                <div className="submenu">
                  {category.children.map((item) => (
                    <MenuLink
                      key={item.link}
                      color={category.color}
                      icon={item.icon}
                      text={item.name}
                      link={item.link}
                      closeMenu={closeMenu}
                    />
                  ))}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="spacer" />
        <div className="dashboard-links">
          <button
            className="button is-primary"
            onClick={() => {
              setDashboardTarget('week');
              setShowPasswordPrompt(true);
            }}
          >
            <i className="fas fa-chart-simple"></i>
          </button>
          <button
            className="button is-primary"
            onClick={() => {
              setDashboardTarget('gallery');
              setShowPasswordPrompt(true);
            }}
          >
            <i className="fas fa-images"></i>
          </button>
        </div>
      </nav>
      {showPasswordPrompt && (
        <Modal onClose={() => setShowPasswordPrompt(false)}>
          <div className="card">
            <div className="card-header">
              <h2 className="card-header-title">What is the password?</h2>
            </div>
            <div className="card-content">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  window.location.href = `/dashboard/${dashboardTarget}?token=${encodeURIComponent(password)}`;
                }}
              >
                <div className="field">
                  <input
                    autoFocus
                    type="password"
                    className="input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button className="button is-link is-pulled-right" disabled={!password}>
                  Open
                </button>
              </form>
              <div className="is-clearfix" />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

interface MenuLinkProps {
  icon: string;
  text: string;
  link: string;
  color?: 'green' | 'blue' | 'yellow' | 'red' | 'grey';
  closeMenu: () => void;
}

const MenuLink = ({ icon, text, link, color, closeMenu }: MenuLinkProps) => (
  <NavLink to={link} className="menu-link" end onClick={closeMenu}>
    <i className={`icon fas fa-${icon} ${color}`} />
    {text}
  </NavLink>
);
