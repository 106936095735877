import React, { useEffect, useState } from 'react';
import { useService } from 'react-service-injector';
import { TimeChimpService, TimeDto, User } from '../services/TimeChimpService';
import { BillabilityChart } from '../charts/BillabilityChart';
import { Bar, SingleStatBarChart } from '../charts/SingleStatBarChart';
import { useUserData } from '../hooks/useUserData';
import { DateTime } from 'luxon';
import { DateRangeFormat } from './DateRangeSelector';

interface Props {
  users: User[];
  range: { start: string; end: string };
  includeLeave?: boolean;
  includeSick?: boolean;
}

export const UserCharts: React.FC<Props> = ({ users, range, includeLeave = false, includeSick = true }) => {
  const [times, setTimes] = useState<TimeDto[]>();
  const timesService = useService(TimeChimpService);
  const { timeChimpId, contractWorkWeekHours } = useUserData();

  const [nonBillable, setNonBillable] = useState<Bar[]>([]);
  const [billable, setBillable] = useState<Bar[]>([]);

  useEffect(() => {
    const getTimes = async () => {
      try {
        const newTimes = (
          await timesService.getTimes(range.start, range.end, {
            includeLeave,
            includeSick,
          })
        ).filter((time) => users.some((user) => user.id === time.userId));
        setTimes(newTimes.filter((entry) => !!users.find((user) => user.id === entry.userId)));
      } catch (err) {
        console.error('Could not get times', err);
      }
    };
    getTimes();
  }, [timesService, range, users, includeLeave, includeSick]);

  useEffect(() => {
    if (!times) {
      return;
    }

    const billables: Record<string, number> = {};
    const nonBillables: Record<string, number> = {};

    times.forEach((time) => {
      if (timesService.isNonBillable(time)) {
        nonBillables[time.taskName] = (nonBillables[time.taskName] || 0) + time.hours;
      } else {
        billables[time.projectName] = (billables[time.projectName] || 0) + time.hours;
      }
    });

    setBillable(
      Object.entries(billables).map(([projectName, hours]) => ({
        name: projectName,
        value: hours,
      }))
    );

    setNonBillable(
      Object.entries(nonBillables).map(([taskName, hours]) => ({
        name: taskName,
        value: hours,
      }))
    );
  }, [times, timesService]);

  const billableHours = billable.reduce((prev, next) => prev + next.value, 0);
  const nonBillableHours = nonBillable.reduce((prev, next) => prev + next.value, 0);
  const header = users.length === 1 ? users[0].displayName : `${users.length} people`;

  const numberOfDays =
    DateTime.fromFormat(range.end, DateRangeFormat).diff(DateTime.fromFormat(range.start, DateRangeFormat)).as('days') +
    1;
  const totalHours = billableHours + nonBillableHours;
  const contractHours = (numberOfDays / 7) * contractWorkWeekHours;
  const billablePercent = totalHours === 0 ? 0 : (100 * billableHours) / totalHours;

  return (
    <>
      {users.length === 1 && users[0]?.id === timeChimpId && (
        <div className="columns is-align-items-center">
          <div className="column is-10">
            <progress
              className="progress is-link"
              value={totalHours}
              max={contractHours}
              title={`${totalHours} / ${Math.round(contractHours)} hr`}
            />
          </div>
          <div className="column">
            <label className="label">
              {totalHours} / {Math.round(contractHours)} hours
            </label>
          </div>
        </div>
      )}
      <div className="columns">
        <div className="column">
          <BillabilityChart billablePercent={billablePercent} />
        </div>
        <div className="column">
          {
            <SingleStatBarChart
              title="Total Hours"
              header={header}
              bars={[
                {
                  name: 'Billable',
                  value: billableHours,
                },
                {
                  name: 'Non billable',
                  value: nonBillableHours,
                },
              ]}
            />
          }
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="column">{<SingleStatBarChart title="Non Billable" header={header} bars={nonBillable} />}</div>
        </div>
        <div className="column">
          <div className="column">{<SingleStatBarChart title="Billable" header={header} bars={billable} />}</div>
        </div>
      </div>
    </>
  );
};
