const icons: Record<string, string> = {
  available: 'chair-office',
  otherlocation: 'building-columns',
  extern: 'building',
  home: 'house',
  leave: 'umbrella-beach',
  sick: 'bed',
  unavailable: 'square-xmark',
};

interface Props {
  activity: string;
}

export const ActivityIcon = ({ activity }: Props) => {
  return <i className={`icon fa-regular fa-${icons[activity]}`}></i>;
};
