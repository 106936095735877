import { UserDataProvider } from './hooks/useUserData';
import { NavMenu } from './components/NavMenu';
import { Route, Routes } from 'react-router-dom';
import { DashboardPage } from './pages/DashboardPage';
import { FinancialPage } from './pages/FinancialPage';
import { ProjectImportPage } from './pages/ProjectImportPage';
import { GalleryPage } from './pages/GalleryPage';
import { HealthPage } from './pages/HealthPage';
import { ExpensesPage } from './pages/ExpensesPage';
import { KpiPage } from './pages/KpiPage';
import { MileagePage } from './pages/MileagePage';
import { PlanningPage } from './pages/PlanningPage';
import { PlantsPage } from './pages/PlantsPage';
import { AbsencesPage } from './pages/AbsencesPage';
import { LandingPage } from './pages/LandingPage';
import { ClimatePage } from './pages/ClimatePage';

export const App = () => (
  <UserDataProvider>
    <div className="app">
      <NavMenu />
      <main>
        <div className="content section">
          <Routes>
            <Route index element={<LandingPage />} />
            <Route path="/personal" element={<LandingPage />} />
            <Route path="/personal/dashboard" element={<DashboardPage />} />
            <Route path="/personal/expenses" element={<ExpensesPage />} />
            <Route path="/personal/mileage" element={<MileagePage />} />
            <Route path="/personal/absences" element={<AbsencesPage />} />
            <Route path="/team" element={<LandingPage />} />
            <Route path="/team/financial" element={<FinancialPage />} />
            <Route path="/team/health" element={<HealthPage />} />
            <Route path="/team/kpis" element={<KpiPage />} />
            <Route path="/team/planning" element={<PlanningPage view="users" />} />
            <Route path="/team/project-import" element={<ProjectImportPage />} />
            <Route path="/office" element={<LandingPage />} />
            <Route path="/office/planning" element={<PlanningPage view="activities" />} />
            <Route path="/office/gallery" element={<GalleryPage />} />
            <Route path="/office/plants" element={<PlantsPage />} />
            <Route path="/office/climate" element={<ClimatePage />} />
          </Routes>
        </div>
      </main>
    </div>
  </UserDataProvider>
);
