import { HealthDetails } from '../components/health/HealthDetails';
import { useState } from 'react';
import { classNames } from '../utils/classNames';
import { HealthOverview } from '../components/health/HealthOverview';

type HealthView = 'details' | 'overview';

export const HealthPage = () => {
  const [view, setView] = useState<HealthView>('details');

  return (
    <div>
      <div className="columns">
        <div className="column">
          <h1>Team Health</h1>
        </div>
        <div className="column">
          <div className="buttons has-addons is-pulled-right">
            <button
              className={classNames('button', view === 'details' && 'is-link is-selected')}
              onClick={() => setView('details')}
            >
              Details
            </button>
            <button
              className={classNames('button', view === 'overview' && 'is-link is-selected')}
              onClick={() => setView('overview')}
            >
              Overview
            </button>
          </div>
        </div>
      </div>
      {view === 'details' && <HealthDetails />}
      {view === 'overview' && <HealthOverview />}
    </div>
  );
};
