import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React from 'react';
import { colors } from './options';

export interface Props {
  billablePercent: number;
}

export const BillabilityChart = ({ billablePercent }: Props) => {
  const billabilityGaugeOptions = {
    chart: {
      type: 'solidgauge',
      backgroundColor: 'transparent',
    },
    pane: {
      center: ['50%', '85%'],
      size: '140%',
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: 'lightgrey',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc',
      },
    },
    title: {
      text: '<h1>Billability</h1>',
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          borderWidth: 0,
          useHTML: true,
        },
      },
    },
    series: [
      {
        data: [Math.round(billablePercent)],
        dataLabels: {
          format: '<span>{y} %</span>',
        },
      },
    ],
    yAxis: {
      min: 0,
      max: 100,
      stops: [
        [0, colors.red],
        [0.74, colors.yellow],
        [0.75, colors.green],
      ],
      minorTickInterval: null,
      tickInterval: 25,
      labels: {
        y: 16,
      },
    },
  };

  return (
    <div className="billability-chart">
      <HighchartsReact highcharts={Highcharts} options={billabilityGaugeOptions} />
    </div>
  );
};
