import { ApiService } from './ApiService';
import { Injector } from 'react-service-injector';
import { Buffer } from 'buffer';
import { User } from './TimeChimpService';

export class ReportService {
  public static readonly NAME = 'ReportService';

  private readonly api: ApiService;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiService);
  }

  public async getMileageReport(start: string, end: string, isPreview: boolean, selectedUser: User | undefined) {
    if (isPreview) {
      return Buffer.from(await this.generatePreviewReport(start, end, selectedUser?.id));
    } else {
      return Buffer.from(await this.generateReport(start, end, selectedUser?.id));
    }
  }

  public generateReport(start: string, end: string, userId?: number): Promise<Buffer> {
    return this.api.jsonGet(`/reports/range/${start}/${end}`, { userId });
  }

  public generatePreviewReport(start: string, end: string, userId?: number): Promise<Buffer> {
    return this.api.jsonGet(`/reports/preview/range/${start}/${end}`, { userId });
  }
}
