import { useCurrentUser, useUsers } from '../hooks/useUserData';
import { useState } from 'react';
import { DateRangeSelector, Range } from '../components/DateRangeSelector';
import { UserCharts } from '../components/UserCharts';
import { PeopleSelector } from '../components/PeopleSelector';
import { User } from '../services/TimeChimpService';

export const DashboardPage = () => {
  const users = useUsers();
  const [range, setRange] = useState<Range>({});
  const [selectedUser, setSelectedUser] = useState<User | undefined>(useCurrentUser());

  const [includeLeave, setIncludeLeave] = useState(false);
  const [includeSick, setIncludeSick] = useState(true);

  return (
    <>
      <div className="columns">
        <div className="column">
          <h1>Dashboard</h1>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <PeopleSelector selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
        </div>
        <DateRangeSelector range={range} onRangeChange={setRange} />
        <div className="column">
          <div className="field">
            <label className="label">Include absence</label>
            <input
              id="includeLeave"
              className="switch"
              type="checkbox"
              checked={includeLeave}
              onChange={(e) => setIncludeLeave(e.target.checked)}
            />
            <label htmlFor="includeLeave" />
          </div>
        </div>
        <div className="column">
          <div className="field">
            <label className="label">Include sick leave</label>
            <input
              id="includeSick"
              className="switch"
              type="checkbox"
              checked={includeSick}
              onChange={(e) => setIncludeSick(e.target.checked)}
            />
            <label htmlFor="includeSick" />
          </div>
        </div>
      </div>
      {range.start && range.end && users && (
        <UserCharts
          users={selectedUser ? [selectedUser] : users}
          range={{ start: range.start, end: range.end }}
          includeLeave={includeLeave}
          includeSick={includeSick}
        />
      )}
    </>
  );
};
