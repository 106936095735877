import { DayPlanning } from '../../services/PlanningService';
import { ActivityIcon } from './ActivityIcon';

interface Props {
  planning: DayPlanning[];
  weekday: number;
  userName: string;
}

export const UserPlanningCell = ({ planning, weekday, userName }: Props) => (
  <>
    {planning
      .filter((planning) => planning.weekday === weekday && planning.email === userName)
      .map((planning) => {
        const parts = planning.planning && planning.planning.match(/^([a-z]+)-([a-z]+)$/);
        if (parts) {
          return (
            <td key={weekday + '-' + userName} className={`row-${planning.planning}`}>
              <div className="activity">
                <span className={parts[1]}>
                  <ActivityIcon activity={parts[1]} />
                </span>{' '}
                /{' '}
                <span className={parts[2]}>
                  <ActivityIcon activity={parts[2]} />
                </span>
              </div>
            </td>
          );
        }
        return (
          <td key={weekday + '-' + userName} className={`row-${planning.planning}`}>
            <div className="activity">
              <span className={planning.planning}>
                <ActivityIcon activity={planning.planning} />
              </span>
            </div>
          </td>
        );
      })}
  </>
);
