import { Injector } from 'react-service-injector';
import { ApiService } from './ApiService';

export interface Item {
  userId: number;
  userName: string;
  projectId: number;
  taskId: number;
  duration: number;
  datetime: string;
  description: string;
  billable: boolean;
}

export interface Project {
  id: number;
  name: string;
}

export interface Task {
  projectId: number;
  id: number;
  name: string;
}

export class AlpacaService {
  private readonly api: ApiService;

  constructor(injector: Injector) {
    this.api = injector.resolve(ApiService);
  }

  public getProjects(): Promise<Project[]> {
    return this.api.jsonGet('/alpaca/projects');
  }

  public getProjectTasks(projectId: number): Promise<Task[]> {
    return this.api.jsonGet(`/alpaca/project/${projectId}/tasks`);
  }

  public getItems(projectId: number, start: string, end: string): Promise<Item[]> {
    return this.api.jsonGet(`/alpaca/project/${projectId}/${start}/${end}`);
  }
}
