import { useEffect, useState } from 'react';
import { useService } from 'react-service-injector';
import { Gallery, GalleryService } from '../services/GalleryService';

export const GalleryPage = () => {
  const [galleries, setGalleries] = useState<Gallery[]>([]);
  const service = useService(GalleryService);

  useEffect(() => {
    service.getGalleries().then(setGalleries);
  }, [service]);

  return (
    <div className="gallery-page">
      <div className="columns">
        <div className="column">
          <h1>Gallery</h1>
        </div>
      </div>
      <div>
        {galleries.map((gallery) => (
          <div key={gallery.title} className="gallery">
            <h2>{gallery.title}</h2>
            {gallery.photos.map((img) => (
              <div key={`${gallery.title}/${img.title}`} className="photo">
                <img className="thumbnail" src={img.publicUrl} alt={img.title} />
                <div>{img.title}</div>
              </div>
            ))}
            <hr />
          </div>
        ))}
      </div>
    </div>
  );
};
